import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

// Sevices
import { formatDate } from "../../../components/FormatDate";
import { getClinicDetails } from "../../../services/admin/serviceClinic";

const View = () => {
  const { uid } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userDetails, setDetails] = useState(null);
  const [isValueLoded, valueLoded] = useState(false);

  //------------------------- FUCNCTIONS ------------------
  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (userDetails == null) {
      fetchData();
      //valueLoded(false)
    } else {
      valueLoded(true);
    }
  }, [userDetails]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getClinicDetails(uid);
      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
        setLoading(false);
      }
      if (response.status == 1) {
        setDetails(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  // ---------------------------------- Render Html
  return userDetails ? (
    <div className="container-fluid mt-5">
      <div className="form__box__container">
        <h3 className="head__weekly__report">View Clinic</h3>
        <div className="row mt-3">
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Clinic Name
              </label>
              <label for="" class="form-label">
                {userDetails?.name}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Email
              </label>
              <label for="" class="form-label">
                {userDetails.user.email}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Contact Number
              </label>
              <label for="" class="form-label">
                {userDetails.user.contactNumber}
              </label>
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Address
              </label>
              <label for="" class="form-label">
                {userDetails?.addressLine1}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Country
              </label>
              <label for="" class="form-label">
                {userDetails?.country}
              </label>
            </div>
          </div>
          {/* <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Address line 2</label>
                                <label for="" class="form-label">{userDetails?.addressLine2}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Landmark</label>
                                <label for="" class="form-label">{userDetails?.landmark}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">City</label>
                                <label for="" class="form-label">{userDetails?.city}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">State</label>
                                <label for="" class="form-label">{userDetails?.state}</label>
                            </div>
                        </div> */}
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Document
              </label>
              <label for="" class="form-label">
                <a
                  target="_blank"
                  href={userDetails?.user?.documents[0].documentUrl}
                >
                  View
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-between mt-3">
        <button className="btn cancel-btn" onClick={goBack}>
          Back
        </button>
      </div>
    </div>
  ) : (
    ""
  );
};
export default View;
