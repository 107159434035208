import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingDots from "../../../components/LoadingDots";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
// Sevices
import { getDetails, edit } from "../../../services/admin/serviceSpecialist";

const Edit = () => {
  const fileInputRef = useRef(null);
  const { uid } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  var [icon, setIcon] = useState("");
  const [postIcon, setPostIcon] = useState("");
  const [isValueLoded, valueLoded] = useState(false);
  const [details, setDetails] = useState(null);
  //------------------------- FUCNCTIONS ------------------
  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (details == null) {
      fetchData();
    } else {
      valueLoded(true);
    }
  }, [details]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getDetails(uid);
      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
        setLoading(false);
      }
      if (response.status == 1) {
        setDetails(response?.data);
        formik.setFieldValue("name", response?.data?.name);
        const externalFileUrl = response?.data?.icon;
        try {
          const response = await fetch(externalFileUrl);
          const blob = await response.blob();
          let _ext = blob.type.split("/")[1];
          const file = new File([blob], "icon." + _ext, {
            type: blob.type,
            lastModified: new Date().getTime(),
          });
          setPostIcon(file);
        } catch (error) {
          toast.error("Edit image not loading");
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    //file: Yup.string().required("Please upload icon"),
  });

  const formik = useFormik({
    initialValues: {
      name: details?.name ? details?.name : "N/A",
      icon: "",
    },

    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  // ---------- SUBMIT THE FORM -----------
  async function webHandleSubmit(_values) {
    try {
      if (icon == "") {
        icon = postIcon;
      }
      setError("");
      setLoading(true);
      let form = document.getElementById("specialist-form");
      let _form = new FormData(form);
      _form.append("icon", icon);
      _form.append("specialtyID", uid);
      const _response = await edit(_form);
      setLoading(false);
      if (_response.status == 0) {
        toast.error(_response.message);
      }
      if (_response.status == 1) {
        toast.success(_response.message);
        history.push("/admin/speicialist/list");
      }
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  }

  const onSelectImageHandler = (files) => {
    formik.setFieldValue("file", "");
    const file = files[0];
    setIcon(file);
  };

  // ----------------------------------------- RENDER HTML ----------------------------

  return isValueLoded ? (
    <div className="container-fluid">
      <div className="form__box__container">
        <form
          onSubmit={formik.handleSubmit}
          id="specialist-form"
          action="#"
          method="POST"
          enctype="multipart/form-data"
        >
          <div className="row">
            <h3 className="head__weekly__report">Create</h3>
            <div className="col-md-3">
              <div className="mb-2">
                <label for="" className="form-label">
                  Specialist Name
                </label>
                <input
                  type="text"
                  className="form-control cust__form"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="form_errors">{formik.errors.name}</div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-2">
                <label for="" className="form-label">
                  Icon
                </label>
                <input
                  type="file"
                  //name='icon'
                  id="upload-icon"
                  className="form-control cust__form"
                  accept="image/jpeg,jpg,png"
                  onChange={(e) => onSelectImageHandler(e.target.files)}
                  //{...formik.getFieldProps("icon")}
                />
              </div>
            </div>

            {!loading ? (
              <div className="col-md-12 d-flex justify-content-between mt-3">
                <button type="submit" className="btn save-btn">
                  Update
                </button>
                <button className="btn cancel-btn" onClick={goBack}>
                  Back
                </button>
              </div>
            ) : (
              <LoadingDots />
            )}
          </div>
        </form>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Edit;
