import HomeIcon from "./icons/home";
import CreateIcon from "./icons/create";
import ListIcon from "./icons/list";
import TasksIcon from "./icons/tasks";
import ReportsIcon from "./icons/reports";
import SettingsIcon from "./icons/settings";
import CalendarIcon from "./icons/calendar";
import ProjectsIcon from "./icons/projects";
import TimeManageIcon from "./icons/time-manage";
import DocumentationIcon from "./icons/documentation";
import ProfileIcon from "./icons/profile";
import DoctorIcon from "./icons/doctorIcon";
import { TableIcon } from "@heroicons/react/outline";
import { notificationBadgeType } from "../../common/constants";
import {
  BellIcon,
  DotsCircleHorizontalIcon,
  MailOpenIcon,
} from "@heroicons/react/solid";
import StarIcon from "./icons/star";
import EnvelopeIcon from "./icons/envelope";
import MultipleUserIcon from "./icons/multiple-users";

// ================================== ADMIN SECTION START =====================================
export const adminNav = [
  {
    title: "Dashboard",
    icon: <HomeIcon />,
    link: "/admin",
  },
  {
    title: "Doctor",
    icon: <ProjectsIcon />,
    link: "#",
    subMenu: [
      {
        title: "Create",
        link: "/admin/doctor/create",
        icon: <CreateIcon />,
      },
      {
        title: "List",
        link: "/admin/doctor/list",
        icon: <ListIcon />,
      },
    ],
  },
  {
    title: "Patient",
    icon: <ProjectsIcon />,
    link: "#",
    subMenu: [
      {
        title: "Create",
        link: "/admin/patient/create",
        icon: <CreateIcon />,
      },
      {
        title: "List",
        link: "/admin/patient/list",
        icon: <ListIcon />,
      },
    ],
  },
  {
    title: "Specialist",
    icon: <ProjectsIcon />,
    link: "#",
    subMenu: [
      {
        title: "Create",
        link: "/admin/speicialist/create",
        icon: <CreateIcon />,
      },
      {
        title: "List",
        link: "/admin/speicialist/list",
        icon: <ListIcon />,
      },
    ],
  },
  {
    title: "Clinics",
    icon: <ListIcon />,
    link: "/admin/clinic/list",
  },

  // {
  //   title: 'Pending Approvals',
  //   icon: <DotsCircleHorizontalIcon />,
  //   link: '/admin/pending',
  // },
  {
    title: "Rating & Reviews",
    icon: <BellIcon />,
    link: "/admin/ratings",
  },
  // {
  //   title: 'Awaiting',
  //   icon: <DotsCircleHorizontalIcon />,
  //   link: '/admin/awaiting',
  // }
];
// ================================== ADMIN SECTION END =====================================

// ================================== DOCTOR SECTION START =====================================

export const doctorNav = [
  {
    title: "Home",
    icon: <HomeIcon />,
    link: "/doctor",
  },
  {
    title: "Appointment",
    icon: <CalendarIcon />,
    link: "/doctor/appointment",
  },
  {
    title: "Clinic Invites",
    icon: <EnvelopeIcon />,
    link: "/doctor/invitations",
  },
  {
    title: "Patients",
    icon: <MultipleUserIcon />,
    link: "/doctor/patients",
  },
  // {
  //   title: "Memos",
  //   icon: <MailOpenIcon />,
  //   link: "/doctor/memo",
  // },
  {
    title: "Profile",
    icon: <ProfileIcon />,
    link: "/doctor/accountSettings",
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    link: "/doctor/settings",
  },
  // {
  //   title: "Memos",
  //   icon: <MailOpenIcon />,
  //   link: "/doctor/memos/",
  // },
  {
    title: "Memos",
    icon: <MailOpenIcon />,
    link: "/doctor/patients-list",
  },
  {
    title: "Review & Ratings",
    icon: <StarIcon />,
    link: "/doctor/reviewAndRating/list",
  },
  // {
  //   title: 'Add/Manage Patients',
  //   icon: <ProjectsIcon />,
  //   link: '/doctor/managePatients',
  //   badgeType: notificationBadgeType.DOCTOR_REQS
  // },
  // {
  //   title: 'Manage Notifications',
  //   icon: <BellIcon />,
  //   link: '/doctor/manageNotifications'
  // },
];

export const patientNav = [
  {
    title: "Home",
    icon: <HomeIcon />,
    link: "/patient",
  },
  {
    title: "Appointment",
    icon: <CalendarIcon />,
    link: "/patient/appointments",
  },
  // {
  //   title: 'Your Doctors',
  //   icon: <DoctorIcon />,
  //   link: '/patient/doctorList',
  //   badgeType: notificationBadgeType.PATIENT_REQS
  // },
  {
    title: "Book By Specialties",
    icon: <ListIcon />,
    link: "/patient/specialities",
  },
  {
    title: "Medical History",
    icon: <DoctorIcon />,
    link: "/patient/medicalHistory",
  },
  {
    title: "Memos",
    icon: <MailOpenIcon />,
    link: "/patient/memos",
  },
  {
    title: "Profile",
    icon: <ProfileIcon />,
    link: "/patient/accountSettings",
  },

  // {
  //   title: 'Diabetes Management',
  //   icon: <TableIcon />,
  //   link: '/patient/diabetes',
  // },
];

// ================================== Clinic Navigation ========================================
export const clinicNav = [
  {
    title: "Home",
    icon: <HomeIcon />,
    link: "/clinic",
  },
  {
    title: "Doctors",
    icon: <CalendarIcon />,
    link: "/clinic/doctors",
  },
  {
    title: "Requests",
    icon: <MailOpenIcon />,
    link: "/clinic/invitations",
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    link: "/clinic/settings",
  },
  {
    title: "Profile",
    icon: <ProfileIcon />,
    link: "/clinic/accountSettings",
  },
];
