import React, { useEffect, useState } from "react";
import {
  serviceGetAppointmentDetails,
  serviceGetDoctorDetails,
  serviceGetRatingTypes,
  servicePostReview,
} from "../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useParams, Link } from "react-router-dom";
import { useAuth } from "../../useAuth";
import LoadingSpinner from "../../components/LoadingSpinner";
import DefaultAvatar from "../../components/DefaultAvatar";

const PatientPostReview = () => {
  const [loading, setLoading] = useState(false);
  const [ratingParameters, setRatingParameters] = useState([]);
  const [remark, setRemark] = useState("");
  const [ratingData, setRatingData] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState("");
  const [doctorData, setDoctorData] = useState("");
  const { bookingId } = useParams();
  const { userData } = useAuth();
  const history = useHistory();

  let count = 0;
  let ratingInfo = [];

  function addRating(_name, _id, _rating) {
    const existingObject = ratingData.find((obj) => obj.ratingTypeID == _id);
    if (existingObject) {
      existingObject.ratingTypeID = _id;
    } else {
      const newObject = { ratingTypeID: _id, ratingValue: parseInt(_rating) };

      ratingInfo.push(newObject);
      setRatingData((ratingData) => [...ratingData, newObject]);
    }
  }

  async function getRatingTypes() {
    try {
      setLoading(true);
      let response = await serviceGetRatingTypes();
      if (response.status == 1) {
        setRatingParameters(response.data);

        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function getDoctorDetails(doctorId) {
    try {
      setLoading(true);
      let response = await serviceGetDoctorDetails(doctorId);
      if (response.status == 1) {
        setDoctorData(response.data);

        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function getAppointmentDetails() {
    try {
      setLoading(true);
      let doctorId = "";
      let response = await serviceGetAppointmentDetails(bookingId);
      if (response.status == 1) {
        doctorId = response.data.doctor.uid;
        setAppointmentDetails(response.data);
        if (doctorId != "") {
          await getDoctorDetails(doctorId);
        }

        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function postReview() {
    try {
      setLoading(true);
      let data = {
        doctorID: doctorData.uid,
        patientID: userData.uid,
        appointmentID: bookingId,
        review: remark,
        ratings: ratingData,
      };

      const response = await servicePostReview(data);
      if (response.status == 1) {
        setLoading(false);
        history.goBack();
        return toast.success(response.message);
      } else {
        setLoading(false);
        return toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error.message);
    }
  }

  function resetRating() {
    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton) => {
      radioButton.checked = false;
    });
    setRemark("");
  }

  const getData = async () => {
    await getRatingTypes();
    await getAppointmentDetails();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <section className="review__section">
          <div className="container-fluid">
            <div className="docter__setting__header">
              <h2>Post Review</h2>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="main__duration__box">
                  <div className="profile__review">
                    <div className="profile__img__review__box">
                      {/* <div className="profile__img__review"> */}
                      {doctorData?.user?.avatarImageUrl ? (
                        <img
                          src={doctorData?.user?.avatarImageUrl}
                          className="profile__img__details"
                          alt="Doctor Image"
                        />
                      ) : (
                        <DefaultAvatar className="m-auto profile__img__setting" />
                      )}
                      {/* </div> */}
                    </div>
                    <div className="review__doc__desig__box">
                      <h4>
                        Dr.{" "}
                        {doctorData?.user?.firstName +
                          " " +
                          doctorData?.user?.lastName}
                      </h4>
                      <p>{doctorData?.education || ""}</p>
                    </div>
                  </div>

                  <div className="review__star__box">
                    <h6>Rating Type</h6>

                    {ratingParameters.map((_data) => {
                      count++;
                      return (
                        <>
                          <div className="rating__main__box">
                            <div className="rating__txt__star">
                              <p>{_data["name"]}</p>
                            </div>
                            <div className="star__box__review">
                              <div class="star-rating">
                                <input
                                  type="radio"
                                  id={_data["name"] + "_" + "5"}
                                  name={_data + "_" + "5"}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      // setRatingInfo([
                                      //   ...ratingInfo,
                                      //   {
                                      //     name: _data,
                                      //     count: "5",
                                      //   },
                                      // ]);
                                      addRating(
                                        _data["name"],
                                        _data["id"],
                                        "5"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  for={_data["name"] + "_" + "5"}
                                  class="star"
                                >
                                  &#9733;
                                </label>

                                <input
                                  type="radio"
                                  id={_data["name"] + "_" + "4"}
                                  name={_data["name"] + "_" + "4"}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      // setRatingInfo([
                                      //   ...ratingInfo,
                                      //   {
                                      //     name: _data,
                                      //     count: "4",
                                      //   },
                                      // ]);
                                      addRating(
                                        _data["name"],
                                        _data["id"],
                                        "4"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  for={_data["name"] + "_" + "4"}
                                  class="star"
                                >
                                  &#9733;
                                </label>

                                <input
                                  type="radio"
                                  id={_data["name"] + "_" + "3"}
                                  name={_data["name"] + "_" + "3"}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      // setRatingInfo([
                                      //   ...ratingInfo,
                                      //   {
                                      //     name: _data,
                                      //     count: "3",
                                      //   },
                                      // ]);
                                      addRating(
                                        _data["name"],
                                        _data["id"],
                                        "3"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  for={_data["name"] + "_" + "3"}
                                  class="star"
                                >
                                  &#9733;
                                </label>

                                <input
                                  type="radio"
                                  id={_data["name"] + "_" + "2"}
                                  name={_data["name"] + "_" + "2"}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      // setRatingInfo([
                                      //   ...ratingInfo,
                                      //   {
                                      //     name: _data,
                                      //     count: "2",
                                      //   },
                                      // ]);
                                      addRating(
                                        _data["name"],
                                        _data["id"],
                                        "2"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  for={_data["name"] + "_" + "2"}
                                  class="star"
                                >
                                  &#9733;
                                </label>

                                <input
                                  type="radio"
                                  id={_data["name"] + "_" + "1"}
                                  name={_data["name"] + "_" + "1"}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      // setRatingInfo([
                                      //   ...ratingInfo,
                                      //   {
                                      //     name: _data,
                                      //     count: "1",
                                      //   },
                                      // ]);
                                      addRating(
                                        _data["name"],
                                        _data["id"],
                                        "1"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  for={_data["name"] + "_" + "1"}
                                  class="star"
                                >
                                  &#9733;
                                </label>
                              </div>
                            </div>
                            {/* <div className="star__box__val">
                            <p>3.5</p>
                          </div> */}
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="review__txt__box__v">
                    <div class="mb-2">
                      <textarea
                        class="form-control"
                        id=""
                        rows="3"
                        placeholder="Write Your Review"
                        onChange={(e) => setRemark(e.target.value)}
                        value={remark}
                      ></textarea>
                    </div>
                    <div></div>
                    <div className="col-md-12 d-flex justify-content-between">
                      <button
                        className="btn cancel-btn ml-3"
                        onClick={() => resetRating()}
                      >
                        Reset
                      </button>

                      <button
                        type="submit"
                        onClick={() => postReview()}
                        className="setting__doctor__save"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PatientPostReview;
