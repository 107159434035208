import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../healthlink_logo.svg";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  servicePostClinicRegistration,
  servicePostFileUpload,
} from "../../services/clinic/clinicApiService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import FileDropzone from "../../components/FileDropzone";
import { FormProvider, useForm } from "react-hook-form";

const ClinicSignUp = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const methods = useForm({ mode: "onBlur" });

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [clinicFile, setClinicFile] = useState({});

  function onAlertCloseClick() {
    setError("");
  }

  const validationSchema = Yup.object({
    clinicName: Yup.string().required("Clinic name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    addressLineOne: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    contactNumber: Yup.string()
      .min(5, "Mobile number must be at least 5 digits")
      .max(20, "Mobile number must not exceed 20 digits")
      .required("Mobile number is required"),
    pinCode: Yup.string().required("Pin code is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 Characters"),
    passwordConfirm: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Password and confirm password should match"
      ),
    terms: Yup.string().required("Please Accept Terms and Conditions"),
    files: Yup.array()
      .min(1, "Clinic document is required")
      .required("Clinic document is required"),
  });
  const formik = useFormik({
    initialValues: {
      clinicName: "",
      email: "",
      addressLineOne: "",
      country: "",
      contactNumber: "",
      pinCode: "",
      password: "",
      passwordConfirm: "",
      terms: "",
      files: [],
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      webHandleSubmit(values);
    },
  });

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);

      //upload file
      let fileData = {
        file: clinicFile,
        destination: "/clinic",
      };
      const fileResponse = await servicePostFileUpload(fileData);
      if (fileResponse.status == 0) {
        return toast.error(fileResponse.message);
      }
      console.log("v ===================== ", fileResponse);
      let data = {
        clinicName: _values.clinicName,
        email: _values.email || "",
        addressLine1: _values.addressLineOne,
        country: _values.country,
        contactNumber: _values.contactNumber,
        pincode: _values.pinCode,
        password: _values.password,
        documents: [
          {
            name: fileResponse.data.filePath.substring(
              fileResponse.data.filePath.lastIndexOf("/") + 1
            ),
            documentUrl: fileResponse.data.filePath,
          },
        ],
      };

      const registerData = await servicePostClinicRegistration(data);

      if (registerData.status == 0) {
        toast.error(registerData.message);
        setLoading(false);
      }
      if (registerData.status == 1) {
        history.replace("/");
        history.push("/verify-email/clinic/" + registerData.data.credentialID);
        toast.success(registerData.message);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
    }
  }

  const handleFileSelect = async (_selectedFile) => {
    try {
      if (_selectedFile) {
        if (_selectedFile.size <= 5 * 1024 * 1024) {
          formik.setFieldValue("files", [_selectedFile]);
          setClinicFile(_selectedFile);
        } else {
          setClinicFile(null);
          return toast.error("File size exceeds 5 MB");
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="min-h-full">
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-12">
                <Link to="/" className="flex items-center justify-center">
                  <img src={logo} className="h-10 w-10 mr-2" alt="logo" />
                  <span className="font-extrabold text-indigo-600 text-3xl">
                    HealthLink <sub className="text-base font-bold">Clinic</sub>
                  </span>
                </Link>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                  Clinic Registration
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Or{" "}
                  <Link
                    to="/patientLogin"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    login with an existing account
                  </Link>
                </p>
              </div>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={formik.handleSubmit} action="#" method="POST">
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Clinic Name</label>
                    <input
                      type="text"
                      {...formik.getFieldProps("clinicName")}
                      className="form-control cust__form"
                    />
                    {formik.touched.clinicName && formik.errors.clinicName && (
                      <div className="form_errors">
                        {formik.errors.clinicName}
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Email ID</label>
                    <input
                      type="email"
                      className="form-control cust__form"
                      ref={emailRef}
                      id="emailRef"
                      name="emailRef"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      title="Please enter only lowercase letters"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="form_errors">{formik.errors.email}</div>
                    )}
                  </div>
                </div>

                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Address</label>
                    <textarea
                      {...formik.getFieldProps("addressLineOne")}
                      className="form-control cust__form"
                    ></textarea>
                    {/* <input
                      type="text"
                      {...formik.getFieldProps("addressLineOne")}
                      className="form-control cust__form"
                    /> */}
                    {formik.touched.addressLineOne &&
                      formik.errors.addressLineOne && (
                        <div className="form_errors">
                          {formik.errors.addressLineOne}
                        </div>
                      )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Country</label>
                    <input
                      type="text"
                      className="form-control cust__form"
                      {...formik.getFieldProps("country")}
                    />
                    {formik.touched.country && formik.errors.country && (
                      <div className="form_errors">{formik.errors.country}</div>
                    )}
                  </div>
                </div>

                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Mobile</label>
                    <div className="form-control cust__form">
                      <PhoneInput
                        className={"input-phone-number"}
                        placeholder="Enter phone number"
                        name="contactNumber"
                        value={contactNumber}
                        maxLength={15}
                        onChange={(e) =>
                          formik.setFieldValue("contactNumber", e)
                        }
                      />
                    </div>
                    {formik.touched.contactNumber &&
                      formik.errors.contactNumber && (
                        <div className="form_errors">
                          {formik.errors.contactNumber}
                        </div>
                      )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Pin Code</label>
                    <input
                      type="text"
                      maxlength={20}
                      {...formik.getFieldProps("pinCode")}
                      className="form-control cust__form"
                    />
                    {formik.touched.pinCode && formik.errors.pinCode && (
                      <div className="form_errors">{formik.errors.pinCode}</div>
                    )}
                  </div>
                </div>

                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      className="form-control cust__form"
                      ref={passwordRef}
                      id="passwordRef"
                      name="passwordRef"
                      {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="form_errors">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control cust__form"
                      ref={passwordConfirmRef}
                      id="passwordConfirm"
                      name="passwordConfirm"
                      {...formik.getFieldProps("passwordConfirm")}
                    />
                    {formik.touched.passwordConfirm &&
                      formik.errors.passwordConfirm && (
                        <div className="form_errors">
                          {formik.errors.passwordConfirm}
                        </div>
                      )}
                  </div>
                </div>

                <div className="row justify-content-center mb-3">
                  <div className="col-md-8">
                    <label className="form-label">
                      Clinic Document<span>&nbsp;</span>
                      <input
                        type="hidden"
                        className="form-control"
                        name="files"
                      ></input>
                      {formik.touched.files && formik.errors.files && (
                        <div className="form_errors">{formik.errors.files}</div>
                      )}
                    </label>
                    <FileDropzone
                      accept=".pdf, image/jpeg, image/png, image/jpg"
                      name="idImage"
                      handleFileSelect={handleFileSelect}
                    />
                    <div className="form_errors">
                      Note: Please upload image or PDF files only, and the file
                      size should be below 5 MB{" "}
                    </div>
                  </div>

                  {/* <div className="col-md-4"></div> */}
                </div>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      name="terms"
                      {...formik.getFieldProps("terms")}
                      required
                    />
                    <label
                      className="form-check-label ml-3"
                      for="exampleCheck1"
                    >
                      I Agree To Terms and Condition
                    </label>
                    {formik.touched.terms && formik.errors.terms && (
                      <div className="form_errors">{formik.errors.terms}</div>
                    )}
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="row justify-content-end mb-3">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <button className="btn save-btn"> Save</button>
                    <Link to="/">
                      {" "}
                      <button className="btn cancel-btn ml-3">Back</button>
                    </Link>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
          <div className="max-w-md w-full space-y-8">
            <div></div>
            {error && (
              <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-600">
                <span className="inline-block align-middle mr-8">
                  <b className="capitalize">Error:</b> {error}
                </span>
                <button
                  onClick={onAlertCloseClick}
                  className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                >
                  <span>×</span>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ClinicSignUp;
