import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  servicePostMemoExamination,
  serviceGetMemoExamination,
} from "../../services/apiService";
import LoadingSpinner from "../../components/LoadingSpinner";

const MemoExamination = ({ patientId, appointmentId }) => {
  const formRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { userData } = useAuth();

  const validationSchema = Yup.object({
    reasonToVisit: Yup.string().required("Reason to visit is required"),
    highBloodPressure: Yup.string().required("High blood presure is required"),
    lowBloodPressure: Yup.string().required("Low blood presure is required"),
    heartRate: Yup.string().required("Heart rate is required"),
    respiratoryRate: Yup.string().required("Respiratory rate is required"),
    temperature: Yup.string().required("Temperature is required"),
    physicalDescription: Yup.string().required(
      "Physical description is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      patientID: patientId,
      doctorID: userData.uid,
      appointmentID: appointmentId,
      reasonToVisit: "",
      highBloodPressure: "",
      lowBloodPressure: "",
      heartRate: "",
      respiratoryRate: "",
      temperature: "",
      physicalDescription: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
      webHandleSubmit(values);
    },
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await serviceGetMemoExamination(appointmentId);

      if (response.status == 0) {
        setLoading(false);
        toast.error(response.message);
      }
      if (response.status == 1) {
        setLoading(false);
        const examinationData = response.data;
        const newValues = {
          patientID: patientId,
          doctorID: userData.uid,
          appointmentID: appointmentId,
          reasonToVisit: examinationData.reasonToVisit || "",
          highBloodPressure: examinationData?.highBloodPressure || "",
          lowBloodPressure: examinationData?.lowBloodPressure || "",
          heartRate: examinationData?.heartRate || "",
          respiratoryRate: examinationData?.respiratoryRate || "",
          temperature: examinationData?.temperature || "",
          physicalDescription: examinationData?.physicalDescription || "",
        };
        formik.setValues(newValues);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = _values;
      const response = await servicePostMemoExamination(data);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        toast.success(response.message);
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="gap-3 setting__form">
            <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
              <form
                ref={formRef}
                onSubmit={formik.handleSubmit}
                action="#"
                method="POST"
                className="col-span-2"
              >
                <div className="form__box__container">
                  <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Reason to visit</b>
                      </label>
                      <input
                        type="text"
                        className="form-control cust__form"
                        placeholder="Reason to visit"
                        {...formik.getFieldProps("reasonToVisit")}
                      />

                      {formik.touched.reasonToVisit &&
                        formik.errors.reasonToVisit && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.reasonToVisit}
                          </p>
                        )}
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>High Blood Presure</b>
                      </label>
                      <input
                        type="number"
                        className="form-control cust__form"
                        placeholder="High Blood Pressure"
                        {...formik.getFieldProps("highBloodPressure")}
                      />

                      {formik.touched.highBloodPressure &&
                        formik.errors.highBloodPressure && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.highBloodPressure}
                          </p>
                        )}
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Low Blood Presure</b>
                      </label>
                      <input
                        type="number"
                        className="form-control cust__form"
                        placeholder="High Blood Pressure"
                        {...formik.getFieldProps("lowBloodPressure")}
                      />

                      {formik.touched.lowBloodPressure &&
                        formik.errors.lowBloodPressure && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.lowBloodPressure}
                          </p>
                        )}
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Heart Rate</b>
                      </label>
                      <input
                        type="number"
                        className="form-control cust__form"
                        placeholder="Heart Rate"
                        {...formik.getFieldProps("heartRate")}
                      />

                      {formik.touched.heartRate && formik.errors.heartRate && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.heartRate}
                        </p>
                      )}
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Respiratory Rate</b>
                      </label>
                      <input
                        type="number"
                        className="form-control cust__form"
                        placeholder="Respiratory Rate"
                        {...formik.getFieldProps("respiratoryRate")}
                      />

                      {formik.touched.respiratoryRate &&
                        formik.errors.respiratoryRate && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.respiratoryRate}
                          </p>
                        )}
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Temperature</b>
                      </label>
                      <input
                        type="number"
                        className="form-control cust__form"
                        placeholder="Temperature"
                        {...formik.getFieldProps("temperature")}
                      />

                      {formik.touched.temperature &&
                        formik.errors.temperature && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.temperature}
                          </p>
                        )}
                    </div>

                    <div className="col-span-2 sm:col-span-4">
                      <label className="form-label">
                        <b>Physical Description</b>
                      </label>
                      <textarea
                        rows={5}
                        className="form-control cust__form"
                        placeholder="Physical Description"
                        {...formik.getFieldProps("physicalDescription")}
                      />

                      {formik.touched.physicalDescription &&
                        formik.errors.physicalDescription && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.physicalDescription}
                          </p>
                        )}
                    </div>
                  </div>
                  <br></br>
                  <div style={{ marginLeft: "45%" }}>
                    <button type="submit" className="setting__doctor__save">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemoExamination;
