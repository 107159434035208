import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  servicePostMemoTreatmentPlan,
  serviceGetTreatmentPlan,
} from "../../services/apiService";
import LoadingSpinner from "../../components/LoadingSpinner";

const MemoTreatMentPlan = ({ patientId, appointmentId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState({});
  const [error, setError] = useState(false);
  const [initialValues, setInitialValues] = useState({
    items: [],
  });
  const { userData } = useAuth();
  const [refreshKey, setRefreshKey] = useState(0);
  const [treatmentData, setTreatMentData] = useState({});

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    let requestData = [];
    values?.items?.forEach((element) => {
      let record = {
        patientID: patientId,
        doctorID: userData.uid,
        appointmentID: appointmentId,
        medicine: element.medicine,
        quantity: element.quantity,
        duration: element.duration,
      };
      requestData.push(record);
    });
    webHandleSubmit(requestData);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await serviceGetTreatmentPlan(appointmentId);
      setInitialValues({
        items: [
          {
            medicine: "",
            quantity: "",
            duration: "",
          },
        ],
      });
      setRefreshKey((prevKey) => prevKey + 1);

      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        const _responseData = response.data;
        setTreatMentData(_responseData);
        let requestData = [];
        let record = {};
        let newItems = [];
        if (_responseData.length == 0) {
          newItems.push({
            medicine: "",
            quantity: "",
            duration: "",
          });
        }

        _responseData?.map((element, index) => {
          record["patientID"] = patientId;
          record["doctorID"] = userData.uid;
          record["appointmentID"] = appointmentId;
          record["items." + [index] + ".medicine"] = element.medicine;
          record["items." + [index] + ".quantity"] = element.quantity;
          record["items." + [index] + ".duration"] = element.duration;
          requestData.push(record);

          newItems.push({
            medicine: element.medicine,
            quantity: element.quantity,
            duration: element.duration,
          });
        });
        initialValues.items = newItems;
        setInitialValues({ items: newItems }); //working
        setRefreshKey((prevKey) => prevKey + 1);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = _values;
      const response = await servicePostMemoTreatmentPlan(data);
      if (response.status != 1) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        toast.success(response.message);
      }
      fetchData();
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="gap-3 setting__form">
            <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
              <Formik
                key={refreshKey}
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {({ values }) => (
                  <Form key={refreshKey} Class="col-span-2">
                    <div className="form__box__container">
                      <div className="row">
                        <br></br>

                        <FieldArray name="items">
                          {({ push, remove }) => (
                            <div className="row">
                              <br></br>
                              {values?.items.map((item, index) => (
                                <div
                                  className="row"
                                  style={{ marginTop: "15px" }}
                                >
                                  <div className="col-3">
                                    <Field
                                      type="text"
                                      name={`items.${index}.medicine`}
                                      placeholder="Medicine"
                                      Class="form-control cust__form col-4"
                                    />
                                  </div>
                                  <div className="col-3">
                                    <Field
                                      type="text"
                                      name={`items.${index}.quantity`}
                                      placeholder="Quantity"
                                      Class="form-control cust__form col-4"
                                    />
                                  </div>
                                  <div className="col-3">
                                    <Field
                                      type="number"
                                      name={`items.${index}.duration`}
                                      placeholder="Duration In Days"
                                      Class="form-control cust__form col-4"
                                    />
                                  </div>
                                  <div className="col-3">
                                    <div style={{ marginLeft: "45%" }}>
                                      {index != 0 ? (
                                        <button
                                          className="btn btn-danger"
                                          type="button"
                                          onClick={() => remove(index)}
                                        >
                                          Remove
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                      &nbsp;
                                      {index == 0 ? (
                                        <button
                                          type="button"
                                          className="btn btn-success"
                                          onClick={() =>
                                            push({
                                              medicine: "",
                                              quantity: "",
                                              duration: "",
                                            })
                                          }
                                        >
                                          Add Item
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <br></br>
                                </div>
                              ))}
                            </div>
                          )}
                        </FieldArray>
                        {/* <button type="submit">Submit</button> */}
                      </div>
                      <br></br>
                      <div style={{ marginLeft: "45%" }}>
                        <button type="submit" className="setting__doctor__save">
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemoTreatMentPlan;
