import { useEffect, useState } from "react";
import { capitalizeFirstChar, getAge } from "../../helper/utils"
import {
  serviceGetDoctorDetails,
  serviceGetDoctorReviews,
} from "../../services/apiService";
import { useParams, useHistory } from "react-router";
import LoadingDots from "../LoadingDots";
import { toast } from "react-toastify";
import RatingStars from "../ratings/RatingStars";
import { useAuth } from "../../useAuth";
import PatientEditReview from "../patient/PatientEditReview";
import { Link } from "react-router-dom";
import { formatDate } from "../FormatDate";

const DoctorDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [doctorDetails, setDoctorDetail] = useState([]);
  const [doctorReviews, setDoctorReviews] = useState([]);
  const [rate, setRate] = useState(0);
  const { userData } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [review, setReview] = useState("");
  const [reviewId, setReviewId] = useState("");

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    fetchDoctorDetails();
    fetchDoctorReiews();
  }, [showModal]);

  const openModal = (_id, _review) => {
    setReviewId(_id);
    setReview(_review);
    setShowModal(!showModal);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    fetchDoctorReiews();
    setShowModal(false);
  };

  const fetchDoctorDetails = async () => {
    try {
      const doctorDetails = await serviceGetDoctorDetails(id);
      if (doctorDetails.status != 1) {
        toast.error(doctorDetails.message);
      }
      setDoctorDetail(doctorDetails.data);
      setRate(
        doctorDetails?.data?.averageRating &&
          doctorDetails?.data?.averageRating != "NaN"
          ? doctorDetails?.data?.averageRating
          : 0
      );
    } catch (error) {
      toast.error("Failed to load doctor details.");
    } finally {
      setLoading(false);
    }
  };

  const fetchDoctorReiews = async () => {
    try {
      const doctorReviews = await serviceGetDoctorReviews(id);
      if (doctorReviews.status != 1) {
        toast.error(doctorReviews.message);
      }
      if (doctorReviews?.data && doctorReviews.data?.length > 0) {
        setDoctorReviews([doctorReviews.data[0]]);
      }
    } catch (error) {
      toast.error("Failed to load doctor reviews.");
    }
  };

  function calculateAverageRating(_ratingArr) {
    let totalCount = 0;
    let totalRating = 0;
    let averageRating = "";
    if (_ratingArr.length > 0) {
      totalCount = _ratingArr.length;
      _ratingArr.map((rating) => (totalRating += rating.rating));

      averageRating = displayStarRating(totalRating / totalCount);
      return averageRating;
    }
    return averageRating;
  }

  function displayStarRating(ratingValue) {
    const maxRating = 5;
    const fullStar = "★";
    const emptyStar = "☆";

    const fullStars = Math.round(ratingValue);
    const emptyStars = maxRating - fullStars;

    const stars = fullStar.repeat(fullStars) + emptyStar.repeat(emptyStars);

    return `${stars}`;
  }

  return !loading ? (
    <div id="container-fluid">
      <div className="form__box__container">
        <div className="doctor-image">
          {doctorDetails?.user?.avatarImageUrl ? (
            <img
              src={doctorDetails?.user?.avatarImageUrl}
              className="profile__img__details"
              alt="Doctor Image"
            />
          ) : (
            <div className="profile__img__details"></div>
          )}
        </div>

        <div className="doctor-contact">
          <h3 className="doctor-name">
            Dr. {doctorDetails?.user?.firstName || ""}{" "}
            {doctorDetails?.user?.lastName}
            <label
              style={{ fontSize: "small", fontStyle: "italic", width: "100%" }}
            >
              {doctorDetails?.about || ""}
            </label>
          </h3>
          <RatingStars rate={rate} setRate={setRate} readOnly={true} />
        </div>

        <div className="row">
          <div className="col-sm-4">
            <label className="patient__details__label">Contact: </label>
            {doctorDetails?.user?.contactNumber || "N/A"}
          </div>
          <div className="col-sm-4">
            <label className="patient__details__label">Gender: </label>
            {doctorDetails?.user?.gender || "N/A"}
          </div>
          <div className="col-sm-4">
            <label className="patient__details__label">Age:</label>
            {getAge(doctorDetails?.user?.dob) || "N/A"}
          </div>
          {/* <div className="col-sm-4">
            <label className="patient__details__label">Date of Birth:</label>
            {formatDate(doctorDetails?.user?.dob) || "N/A"}
          </div> */}
          <div className="col-sm-4">
            <label className="patient__details__label">Email :</label>
            {doctorDetails?.user?.email || "N/A"}
          </div>
          <div className="col-sm-4">
            <label className="patient__details__label">Specialization :</label>
            {capitalizeFirstChar(doctorDetails?.specialities[0]?.masterSpeciality.name) || "N/A"}
          </div>
          {/* <div className="col-sm-4">
            <label className="patient__details__label">Practice :</label>
            {doctorDetails?.medicalPractice || "N/A"}
          </div> */}
          <div className="col-sm-4">
            <label className="patient__details__label">Education :</label>
            {doctorDetails?.education || "N/A"}
          </div>
          <div className="col-sm-4">
            <label className="patient__details__label">Experience :</label>
            {doctorDetails?.experinceInYears || "N/A"} years
          </div>
        </div>

        {doctorDetails?.clinics?.length > 0 && (
          <>
            {/* <h4 className="clinic-details-title">Clinic Details</h4>

            <table className="clinic-table">
              <thead>
                <tr>
                  <th style={{ paddingRight: 20 }}>Clinic Name</th>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                {(doctorDetails?.clinics).map((clinic) => (
                  <tr>
                    <td style={{ paddingRight: 20 }}>{clinic?.clinicName}</td>
                    <td>
                      {(clinic?.addressLine1
                        ? clinic?.addressLine1 + " "
                        : "") +
                        (clinic?.landmark ? clinic?.landmark + " " : "") +
                        (clinic?.city ? clinic?.city + " " : "") +
                        (clinic?.state ? clinic?.state + " " : "") +
                        (clinic?.pincode ? clinic?.pincode + " " : "")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
          </>
        )}
        <br></br>
        {doctorReviews && doctorReviews.length > 0 ? (
          <div className="row text-center">
            <h5>User Review's</h5>
          </div>
        ) : (
          ""
        )}
        {/* {JSON.stringify(doctorReviews)} */}
        {doctorReviews && doctorReviews.length > 0
          ? doctorReviews.map((el) => (
            <div class="form-group">
              <div className="row">
                <div className="col-6">
                  <label
                    for="exampleFormControlTextarea1 col-12"
                    style={{ fontWeight: "bolder" }}
                  >
                    {el?.patient.firstName || ""} {el?.patient.lastName}
                  </label>
                  <label
                    className="col-12"
                    style={{
                      fontSize: "0.8rem",
                      color: "gray",
                      fontStyle: "italic",
                    }}
                  >
                    On Date: {formatDate(el?.createdAt)},
                    at: {new Date(el?.createdAt).toLocaleTimeString()}
                  </label>
                </div>
              </div>
              <div className="rating">
                <b>Average Rating : </b> {calculateAverageRating(el.ratings)}
              </div>

              <p>

                <div className="col-md-12 d-flex justify-content-between">
                  <p>
                    <b>Review:</b> {el?.review}{" "}
                  </p>
                  {el?.patientID == userData.uid ? (
                    <button
                      className="review-edi-btn"
                      onClick={() => openModal(el?.id, el?.review)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </p>
            </div>
          ))
          : ""}
        {doctorReviews && doctorReviews.length > 0 ? (
          <div className="row text-end">
            <Link
              to={{
                pathname: `/patient/doctor/${id}/reviews`,
              }}
            >
              <button className="btn btn-primary">View All</button>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
      <PatientEditReview
        closeModal={closeModal}
        modalIsOpen={showModal}
        reviewId={reviewId}
        reviewMessage={review}
      />
      <div className="col-md-12 d-flex justify-content-between mt-3">
        <button className="btn cancel-btn" onClick={goBack}>
          Back
        </button>
      </div>
    </div>
  ) : (
    <LoadingDots />
  );
};

export default DoctorDetail;
