import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";
import TableCell from "../../../components/table/TableCell";
import TableBody from "../../../components/table/TableBody";
import TableRow from "../../../components/table/TableRow";
import TableFooter from "../../../components/table/TableFooter";
import TableContainer from "../../../components/table/TableContainer";
import LoadingDots from "../../../components/LoadingDots";
import ToastConfirmation from "../../../components/toastrConfirmation";

import Button from "../../../components/Button";
import { Link, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../../components/DefaultAvatar";
import Pagination from "../../../components/Pagination";
import { axiosInstance } from "../../../common/constants";
import SectionTitle from "../../../components/SectionTitle";
import { ToastContainer, toast } from "react-toastify";
// Sevices
import {
  clinicStatus,
  getAdminList,
  getList,
  verifyClinic,
} from "../../../services/admin/serviceClinic";
import { formatDate } from "../../../components/FormatDate";

const List = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable((p - 1) * resultsPerPage);
  }

  useEffect(() => {
    fetchData();
  }, [pageTable]);

  async function fetchData(keywords = "", isVerified = "") {
    try {
      const res = await getAdminList(
        pageTable,
        resultsPerPage,
        keywords,
        isVerified
      );
      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        setDataTable(res.data);
        setTotalResults(res.count);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  // ----------------------------------- Searh user
  const handleKeyUp = (event) => {
    setPageTable(0);
    let _keyword = event.target.value;
    fetchData(_keyword);
  };
  // ----------------------------------- Delte user
  const handleConfirmation = (id, workType) => {
    const data = {
      id,
      workType,
    };
    toast(
      <ToastConfirmation
        prop1={JSON.stringify(data)}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const dataset = JSON.parse(event.target.dataset.userid);
    const _userId = dataset.id;
    const _status = dataset.workType;
    // Perform the action
    setLoading(true);
    let _data = null;

    let response = null;

    if (_status == "Verify") {
      _data = {
        clinicID: _userId,
      };
      response = await verifyClinic(_data);
    } else if (_status == "Active") {
      _data = {
        clinicID: _userId,
        status: 1,
      };
      response = await clinicStatus(_data);
    } else {
      _data = {
        clinicID: _userId,
        status: 0,
      };
      response = await clinicStatus(_data);
    }

    if (response.status == 0) {
      toast.error(response.message);
    }
    if (response.status == 1) {
      toast.success(response.message);
      fetchData();
    }
    setLoading(false);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  const handleChange = (event) => {
    setPageTable(0);
    document.getElementById("serchText").value = "";

    if (event.target.checked == true) {
      let _isVerified = 0;
      fetchData("", _isVerified);
    } else {
      fetchData();
    }
  };

  //--------------------------------------------- RENDER HTML --------------------
  return (
    <>
      <SectionTitle>Clinics </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 p-0">
            <div class="mb-3">
              <input
                onKeyUp={handleKeyUp}
                type="search"
                class="form-control cust__form"
                id="serchText"
                placeholder="Search by email, name and address"
              />
            </div>
          </div>
          <div className="col-md-4 ms-2">
            <div class="mb-3">
              <input type="checkbox" class="" onChange={handleChange} />
              <label className="ms-2">Pending Approval</label>
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>Clinic Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Verification Status</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {dataTable?.length > 0
                ? dataTable?.map((value, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <div>
                          <p className="font-semibold">{`${value.name}`}</p>
                          <span className="list-email">
                            {value.user?.email}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <p className="font-semibold">
                          {value.addressLine1
                            ? value.addressLine1
                            : "" + value.addressLine2
                            ? value.addressLine2
                            : "" + value.landmark
                            ? value.landmark
                            : "" + value.city
                            ? value.city
                            : "" + value.state
                            ? value.state
                            : "" + value.country
                            ? value.country
                            : "" + value.pincode
                            ? value.pincode
                            : ""}
                        </p>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center text-sm">
                          <p className="font-semibold">
                            {formatDate(value.createdAt)}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        {value.verified == 1 ? (
                          <div className="is-verify">
                            <span>Verified</span>
                          </div>
                        ) : (
                          <Button
                            id={value.uid}
                            workType={"Verify"}
                            onClick={() => {
                              handleConfirmation(value?.uid, "Verify");
                            }}
                          >
                            {" "}
                            Verify{" "}
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        <label className="switch__toggle">
                          <input
                            value={value?.status == 1 ? true : false}
                            checked={value?.status == 1 ? true : false}
                            onChange={() => {
                              value?.status == 1
                                ? handleConfirmation(value?.uid, "Inactive")
                                : handleConfirmation(value?.uid, "Active");
                            }}
                            class="check-toggle__input"
                            type="checkbox"
                            id=""
                          />
                          <span class="slider-checkbox round-50"></span>
                        </label>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: `/admin/clinic/view/${value?.uid}`,
                          }}
                        >
                          <Button> View </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default List;
