import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";
import TableCell from "../../../components/table/TableCell";
import TableBody from "../../../components/table/TableBody";
import TableRow from "../../../components/table/TableRow";
import TableFooter from "../../../components/table/TableFooter";
import TableContainer from "../../../components/table/TableContainer";
import LoadingDots from "../../../components/LoadingDots";
import Button from "../../../components/Button";
import { Link, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../../components/DefaultAvatar";
import Pagination from "../../../components/Pagination";
import { publicAxiosInstance } from "../../../common/constants";
import SectionTitle from "../../../components/SectionTitle";
import { getAppointmentList } from "../../../services/doctor/appointment.doctor.service";
import { servicePostUpdateAppointmentStatus } from "../../../services/apiService";
import { toast } from "react-toastify";
import EyeIcon from "../../../dashboard/sidenavigation/icons/eye";
import { formatTime } from "../../../services/dateService";
import ToastConfirmation from "../../../components/toastrConfirmation";
import SearchIcon from "../../../dashboard/sidenavigation/icons/search";
import { formatDate } from "../../../components/FormatDate";
import RecordPlaceholder from "../../../components/RecordPlaceholder";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    //marginRight: '-50%',
    transform: "translate(-50%, -50%)",
  },
};

const DoctorAppointmentList = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  // const [selectedStatus, setSelectedStatus] = useState(false);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [activeAppointmentData, setActiveAppointmentData] = useState({
    status: null,
    appointmentId: "",
    consultationType: "",
    videoLink: "",
  });

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p - 1);
  }

  useEffect(() => {
    fetchApprovalsList();
  }, [pageTable]);

  async function fetchApprovalsList(search = "") {
    const res = await getAppointmentList(pageTable, search);
    setDataTable(res.data);
    setTotalResults(res.totalCount);
    setLoading(false);
  }

  async function statusChange(details) {
    let data = {
      appointmentID: details.appointmentId,
      accept: details.status,
      videoLink: details.videoLink || "",
    };
    // servicePostUpdateAppointmentStatus
    let _updateAppointmentRes = await servicePostUpdateAppointmentStatus(data);
    if (_updateAppointmentRes.status == 1) {
      try {
        fetchApprovalsList();
        return toast.success("Appointment status updated successfully");
      } catch (e) {
        setLoading(false);
        return toast.error("Something went wrong,please try again");
      }
    } else {
      return toast.error("Something went wrong,please try again");
    }
  }

  // Function to handle opening the modal and setting the selected doctor
  const openModal = () => {
    setShowModal(!showModal);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const handleConfirmation = (data) => {
    data = data ? data : activeAppointmentData;
    if (
      data.consultationType == 1 &&
      data.status == 1 &&
      data.videoLink.toString().length <= 0
    ) {
      closeModal();
      return toast.error(
        "Invalid video link. Please try with valid video link!"
      );
    }

    const _value = JSON.stringify({
      status: data.status,
      appointmentId: data.appointmentId,
      videoLink: data.videoLink,
    });

    toast(
      <ToastConfirmation
        prop1={_value}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    setLoading(true);
    toast.dismiss();
    const data = JSON.parse(event.target.dataset.userid);
    // Perform the action
    statusChange(data);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  const searchAppointment = (event) => {
    let search = document.getElementById("search").value;
    setLoading(true);
    fetchApprovalsList(search);
  };

  const AppointmentVideoLinkModel = () => {
    function isValidURL(str) {
      if (
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
          str
        )
      ) {
        return true;
      } else {
        document
          .getElementById("videoLink")
          .setCustomValidity("Invalid url. Please try again with valid url.");
        document.getElementById("videoLink").reportValidity();
        return false;
      }
    }

    return (
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Doctor Details"
        ariaHideApp={false}
      >
        <div id="doctor-modal">
          <div className="modal-header">
            <h5 className="modal-title">Video Link</h5>
          </div>

          <div className="modal-body">
            <div class="form-group">
              <label htmlFor="videoLink">Video Link</label>
              <textarea
                type="text"
                class="form-control"
                id="videoLink"
                placeholder="Enter video link"
                onChange={(e) => (
                  (e.target.style.height = "auto"),
                  (e.target.style.height = e.target.scrollHeight + "px")
                )}
              ></textarea>
            </div>

            <div class="form-group mt-3 text-end">
              <button
                type="button"
                class="btn btn-primary"
                onClick={(event) => {
                  if (isValidURL(document.getElementById("videoLink").value)) {
                    setActiveAppointmentData({
                      ...activeAppointmentData,
                      videoLink: document.getElementById("videoLink").value,
                    });
                    closeModal();
                    return handleConfirmation({
                      ...activeAppointmentData,
                      videoLink: document.getElementById("videoLink").value,
                    });
                  }
                }}
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-light"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <SectionTitle>Appointments </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div class="col-6 ps-0">
            <input
              type="search"
              class="form-control cust__form"
              id="search"
              placeholder="Search"
              // onKeyUp={(event) => searchReview(event)}
            />
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn d-flex"
              style={{ background: "#4F46E5", color: "white" }}
              onClick={() => searchAppointment()}
            >
              <SearchIcon />
              <p className="m-0">Search</p>
            </button>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8 mt-3">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>Sr.</TableCell>
                <TableCell>Booking No.</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time Slot</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dataTable && dataTable.length > 0 ? (
                dataTable.map((el, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {pageTable * resultsPerPage + (i + 1)}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">{el?.id}</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el.firstName} {el.lastName}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {formatDate(el.date)}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {formatTime(el.startTime)} - {formatTime(el.endTime)}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">{el.email}</p>
                    </TableCell>
                    <TableCell>
                      {/* <button>
                        <p className="font-semibold text-sm">
                          {el.acceptedByDoctor.toString() == "true"
                            ? "Accepted"
                            : el.acceptedByDoctor.toString() == "false"
                            ? "Rejected"
                            : "Pending"}
                        </p>
                      </button> */}
                      {el.status != 2 &&
                      el.status != 3 &&
                      el.status != 4 &&
                      el.status != 5 &&
                      el.status != 6 ? (
                        <select
                          id={el.id}
                          className="doctor__appointment__status"
                          onChange={(event) => {
                            setActiveAppointmentData({
                              ...activeAppointmentData,
                              status: +event.target.value,
                              appointmentId: el.id,
                              consultationType: el.consultationType,
                              videoLink: "",
                            });
                            return el.consultationType == 1 &&
                              event.target.value == 1
                              ? openModal()
                              : handleConfirmation({
                                  ...activeAppointmentData,
                                  status: +event.target.value,
                                  appointmentId: el.id,
                                  consultationType: el.consultationType,
                                  videoLink: "",
                                });
                          }}
                          value={el.status}
                        >
                          <option value={3}>Complete</option>
                          <option value={1}>Accept</option>
                          <option value={2}>Reject</option>
                          <option value={0}>Pending</option>
                        </select>
                      ) : (
                        <p className="ms-2">
                          {el.status == 2 ? (
                            <p style={{ color: "red" }}>Rejected</p>
                          ) : el.status == 3 ? (
                            <p style={{ color: "green" }}>Complete</p>
                          ) : el.status == 4 ? (
                            <p style={{ color: "red" }}>Cancel from patient</p>
                          ) : el.status == 5 ? (
                            <p style={{ color: "orange" }}>
                              On Hold (By Clinic)
                            </p>
                          ) : el.status == 6 ? (
                            <p style={{ color: "orange" }}>
                              On Hold (By Doctor)
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      )}
                    </TableCell>
                    <TableCell className="text-center">
                      <Link
                        to={{ pathname: `/doctor/appointment/detail/${el.id}` }}
                      >
                        <button className="p-1" style={{ color: "blue" }}>
                          <EyeIcon />
                        </button>
                      </Link>
                      <Link
                        to={{ pathname: `/doctor/appointment/edit/${el.id}` }}
                      >
                        {el.status == 5 || el.status == 6 ? (
                          <button>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                          </button>
                        ) : (
                          ""
                        )}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
      <AppointmentVideoLinkModel
        closeModal={closeModal}
        modalIsOpen={showModal}
      />
    </>
  );
};

export default DoctorAppointmentList;
