import { useEffect, useState } from "react";
import {
  serviceClinicSettingUpdate,
  serviceGetClinicSetting,
  serviceGetClinicSettingAffectedRecords,
} from "../../services/clinic/clinicApiService";
import { toast } from "react-toastify";
import LoadingDots from "../../components/LoadingDots";
import Modal from "react-modal";
import { useAuth } from "../../useAuth";
import DatePicker from "react-datepicker";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    //marginRight: '-50%',
    transform: "translate(-50%, -50%)",
  },
};

const ClinicSetting = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clinicDetails, setClinicDetails] = useState(null);
  const initialWeek = {
    0: {
      day: 0,
      name: "SUN",
      startTime: "",
      endTime: "",
    },
    1: {
      day: 1,
      name: "MON",
      startTime: "",
      endTime: "",
    },
    2: {
      day: 2,
      name: "TUE",
      startTime: "",
      endTime: "",
    },
    3: {
      day: 3,
      name: "WED",
      startTime: "",
      endTime: "",
    },
    4: {
      day: 4,
      name: "THU",
      startTime: "",
      endTime: "",
    },
    5: {
      day: 5,
      name: "FRI",
      startTime: "",
      endTime: "",
    },
    6: {
      day: 6,
      name: "SAT",
      startTime: "",
      endTime: "",
    },
  };
  const [week, setWeek] = useState(initialWeek);
  const [excludeDates, setexcludeDates] = useState([]);
  const inititalAffectedRecords = {
    appointmentCount: 0,
    doctorsSlots: 0,
  };
  const [affectedRecords, setAffectedRecords] = useState(
    inititalAffectedRecords
  );
  const { userData } = useAuth();

  useEffect(() => {
    fetchClinicSetting();
  }, []);

  const resetData = () => {
    setexcludeDates([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveClinicSetting();
  };

  const fetchClinicSetting = async () => {
    try {
      setLoading(true);
      resetData();
      const response = await serviceGetClinicSetting();
      setLoading(false);
      if (response.status == 1) {
        const exclude = [];
        await Promise.all(
          response.data.excludeAvailability.map((e) =>
            exclude.push(e.excludeDate)
          )
        );
        setexcludeDates([...exclude]);
        await Promise.all(
          response.data.weekAvailability.map((e) => {
            week[e.day].startTime = e.startTime;
            week[e.day].endTime = e.endTime;
          })
        );
        setWeek({
          ...week,
        });
        setClinicDetails(response.data);
      } else {
        return toast.error("Failed to fetch setting. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      return toast.error("Failed to fetch setting. Please try again.");
    }
  };

  const validateAndGetData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = {
          valid: true,
          message: "",
          data: null,
        };
        const keys = Object.keys(week);

        for (let index = 0; index < keys.length; index++) {
          const e = keys[index];
          if (
            (week[e]["startTime"].length > 0 &&
              week[e]["endTime"].length > 0 &&
              week[e]["startTime"] == week[e]["endTime"]) ||
            (week[e]["startTime"].length > 0 && week[e]["endTime"].length <= 0)
          ) {
            response.valid = false;
            response.message = `Invalid startTime or endTime for day ${week[e]["name"]}`;
            resolve(response);
            break;
          }
        }

        response.data = {
          clinicID: userData.uid,
          week: await Promise.all(
            Object.keys(week).map((e) => ({
              day: e,
              startTime: week[e]["startTime"],
              endTime: week[e]["endTime"],
            }))
          ),
          excludeDates,
        };
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getAffectedRecords = async () => {
    try {
      setLoading(true);
      const validation = await validateAndGetData();
      if (!validation.valid) {
        // toast.error(validation.message);
        setLoading(false);
        return;
      }
      const res = await serviceGetClinicSettingAffectedRecords(validation.data);
      if (res.status == 1) {
        affectedRecords.appointmentCount = res.data.appointmentCount;
        affectedRecords.doctorsSlots = res.data.doctorsSlots;
        setAffectedRecords({
          ...affectedRecords,
        });
        return res.data;
      } else {
        throw "Failed to get affected records. Please try again.";
      }
    } catch (error) {
      throw "Failed to get affected records. Please try again.";
    } finally {
      setLoading(false);
    }
  };

  const saveClinicSetting = async () => {
    try {
      closeModal();
      setLoading(true);
      const validation = await validateAndGetData();
      if (!validation.valid) {
        toast.error(validation.message);
        setLoading(false);
        return;
      }
      const response = await serviceClinicSettingUpdate(validation.data);
      setLoading(false);
      if (response.status == 1) {
        return toast.success("Setting updated successfully.");
      } else {
        return toast.error("Failed to update setting. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      return toast.error("Failed to update setting. Please try again.");
    }
  };

  // Function to handle opening the modal and setting the selected doctor
  const openModal = () => {
    setShowModal(true);
  };

  const showWarning = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      const records = await getAffectedRecords();
      if (
        +affectedRecords.appointmentCount > 0 ||
        +affectedRecords.doctorsSlots > 0
      ) {
        openModal();
      } else {
        saveClinicSetting();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const ConfirmationModal = () => {
    useEffect(() => {});
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Doctor Details"
        ariaHideApp={false}
      >
        <div id="doctor-modal">
          <div className="modal-header">
            <h5 className="modal-title text-danger">
              Are you sure you want to update settings?
            </h5>
          </div>

          <div className="modal-body">
            <div>
              <p>Attention {clinicDetails?.name} Team,</p>
              <p className="text-danger">
                Important Alert: Potential Impact on Appointments and Doctor
                Slots Due to Clinic Settings Update
              </p>
              <p>
                We need to bring to your attention that updating the clinic
                settings may have an effect on appointments and doctor slots.
              </p>
              <p>Currently:</p>
              <p>
                Appointments are scheduled:{" "}
                <span className="text-danger">
                  {affectedRecords.appointmentCount}
                </span>
              </p>
              <p>
                Number of Doctors' slots:{" "}
                <span className="text-danger">
                  {affectedRecords.doctorsSlots}
                </span>
              </p>
              <p>Before proceeding, please consider the following:</p>
              <p>
                Appointment Scheduling: The update to clinic settings could lead
                to adjustments in the appointment scheduling system. Some
                scheduled appointments might be affected during the process.
              </p>
              <p>
                Doctor's Slots: The update might also influence the availability
                of doctor slots, potentially affecting the number of available
                appointments.
              </p>
            </div>

            <div class="form-group mt-3 text-end">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => saveClinicSetting()}
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-light"
                onClick={() => closeModal()}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return !loading ? (
    <>
      <form onSubmit={(e) => showWarning(e)}>
        <div className="docter__setting__header">
          <h2>Availability Schedule</h2>
        </div>

        <div className="container-fluid">
          <div className="main__duration__box">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-xl-6">
                <label color="primary" className="pb-3">
                  <h4>Week Availability</h4>
                </label>
                {Object.keys(week).map((e, i) => (
                  <div className="row pb-3">
                    <div className="col-12 col-sm-12 col-md-2 col-xl-2">
                      <h5>{week[i]?.name}</h5>
                    </div>
                    <div className="col-6 col-sm-6 col-md-5 col-xl-3 text-center">
                      <input
                        type="time"
                        value={week[i]?.startTime}
                        required={week[i].endTime.length > 0}
                        onChange={(e) => {
                          week[i].startTime = e.target.value;
                          if (e.target.value.length <= 0) {
                            week[i].endTime = e.target.value;
                          }
                          setWeek({
                            ...week,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 col-sm-6 col-md-5 col-xl-3 text-center">
                      <input
                        id={"endTime_" + i}
                        type="time"
                        value={week[i]?.endTime}
                        min={week[i]?.startTime}
                        required={week[i].startTime.length > 0}
                        disabled={week[i].startTime.toString().length <= 0}
                        onChange={(e) => {
                          week[i].endTime = e.target.value;
                          setWeek({
                            ...week,
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-xl-6">
                <label color="primary" className="pb-3">
                  <h4>Exclude Dates</h4>
                </label>
                <div>
                  <DatePicker
                    className="form-control"
                    placeholderText="Select a date"
                    dateFormat="MM-dd-yyyy"
                    showMonthDropdown="true"
                    showYearDropdown="true"
                    onChange={(value) => {
                      const dates = excludeDates;
                      dates.push(
                        `${moment(value).format("YYYY-MM-DD")}T00:00:00.000Z`
                      );
                      setexcludeDates([...dates]);
                    }}
                    minDate={new Date()}
                  />
                  <div>
                    {excludeDates.map((e, i) => (
                      <div
                        className="mt-2 d-flex align-item-center border-bottom"
                        style={{ width: "fit-content" }}
                      >
                        <h5>{new Date(e).toLocaleDateString()}</h5>
                        <button
                          type="button"
                          className="btn btn-outline-danger ms-2"
                          onClick={(e) => {
                            const dates = excludeDates;
                            dates.splice(i, 1);
                            setexcludeDates([...dates]);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Save Button */}
              <div className="text-center">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg"
                  disabled={
                    !(
                      new Array(7).findIndex(
                        (el, i) =>
                          week[i].startTime.length > 0 &&
                          week[i].endTime.length > 0
                      ) > -1
                    )
                  }
                >
                  Save
                </button>
              </div>
              {/* End Of Save Button */}
            </div>
          </div>
        </div>
      </form>
      <ConfirmationModal closeModal={closeModal} modalIsOpen={showModal} />
    </>
  ) : (
    <LoadingDots></LoadingDots>
  );
};

export default ClinicSetting;
