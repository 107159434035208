import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  serviceGetAppointmentDetails,
  servicePostEditAppointment,
  serviceGetDoctorAvailability,
  serviceGetDoctorTimeSlots,
} from "../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../components/LoadingSpinner";
import DateInput from "../../components/DateInput";
import moment from "moment";
import { formatDate } from "../../components/FormatDate";

const PatientEditAppointment = () => {
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [gender, setGeder] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [appointment, setAppointment] = useState("");
  const [selectableDates, setSelectableDates] = useState([]);
  const [timeSlotArray, setTimeSlotArray] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [time, setTime] = useState("");
  const [reschedule, setReschedule] = useState(false);
  const [selectedClinicID, setSelectedClinicId] = useState("");

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { userData } = useAuth();
  const { id } = useParams();

  const handleGenderSelect = (event) => {
    setGeder(event.target.value);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    contactNumber: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    age: Yup.string().required("Age is required"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      patientHistory: "",
      gender: "",
      age: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let _time = time.split("-");
      if (_values.time) {
        _time = _values.time.split("-");
      }

      let data = {
        id: id,
        patientID: userData.uid,
        doctorID: selectedDoctor,
        firstName: _values.firstName,
        lastName: _values.lastName,
        email: _values.email,
        phoneNumber: _values.contactNumber,
        age: _values.age,
        gender: _values.gender,
        date: selectedDate,
        startTime: String(_time[0]),
        endTime: String(_time[1]),
        patientHistory: _values.patientHistory,
        clinicID: appointment?.clinicID || null,
        consultationType: appointment?.consultationType || null,
      };

      const response = await servicePostEditAppointment(data);
      if (response.status == 0) {
        setLoading(false);
        return toast.error(response.message);
      }
      if (response.status == 1) {
        toast.success(response.message);
        history.replace("/patient/bookAppointment");
        history.push("/patient/appointments/");
        setLoading(false);
      }
    } catch (e) {
      toast.error(e.message);
      setError(e.message);

      setLoading(false);
    }
  }

  async function getAppointMentDetails(id) {
    try {
      setLoading(true);
      let response = await serviceGetAppointmentDetails(id);
      if (response.status == 1) {
        formik.setFieldValue("firstName", response.data.firstName);
        formik.setFieldValue("lastName", response.data.lastName);
        formik.setFieldValue("age", response.data.age);
        formik.setFieldValue("gender", response.data.gender);
        formik.setFieldValue("email", response.data.email);
        formik.setFieldValue("contactNumber", response.data.phoneNumber);
        formik.setFieldValue("patientHistory", response.data.patientHistory);
        setSelectedDoctor(response.data.doctorID);
        setSelectedClinicId(response.data.clinicID);
        await getDoctorAvailability(
          response.data.doctorID,
          response.data.clinicID
        );
        await handleDateChange(response.data.date);
        setTime(response.data.startTime + "-" + response.data.endTime);
        setAppointment(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function getDoctorAvailability(_doctorId, _clinicID) {
    try {
      console.log("_doctorId,_clinicID", _doctorId, _clinicID);
      let response = await serviceGetDoctorAvailability(_doctorId, _clinicID);
      if (response.status == 1) {
        for (let availableDay of response.data.availability) {
          setSelectableDates((selectableDates) => [
            ...selectableDates,
            availableDay.date,
          ]);
        }
      }
    } catch (error) {}
  }

  const handleDateChange = async (value) => {
    let date = moment(value);
    let formattedDate = date.format("MM-DD-YYYY");
    setSelectedDate(formattedDate);
    await getTimeSlots(
      appointment.doctorID,
      appointment.clinicID,
      formattedDate
    );
  };

  async function getTimeSlots(_doctorID, _clinicID, _date) {
    try {
      let response = await serviceGetDoctorTimeSlots(
        _doctorID,
        _clinicID,
        _date
      );
      if (response.status == 1) {
        setTimeSlotArray(response.data.slots);
      }
    } catch (error) {}
  }

  const DoctorTimeSlots = timeSlotArray.map((option) => (
    <option
      key={option.startTime + "-" + option.endTime}
      value={option.startTime + "-" + option.endTime}
    >
      {option.startTime + "-" + option.endTime}
    </option>
  ));

  const handleSelectedTimeSlot = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  useEffect(() => {
    getAppointMentDetails(id);
  }, []);

  return (
    <>
      <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
        Edit Appointment
      </h2>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="container-fluid mt-2">
          <>
            <div className="container-fluid">
              <div className="form__box__container">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    {/* <h3 className="head__weekly__report">Edit Appointment</h3> */}
                    <div className="col-md-4">
                      <div className="mb-2">
                        <b>
                          <label for="" className="form-label">
                            First Name
                          </label>
                        </b>
                        <input
                          type="text"
                          className="form-control cust__form"
                          id=""
                          {...formik.getFieldProps("firstName")}
                        />
                        {formik.touched.firstName &&
                          formik.errors.firstName && (
                            <div className="form_errors">
                              {formik.errors.firstName}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <b>
                          <label for="" className="form-label">
                            Last Name
                          </label>
                        </b>
                        <input
                          type="text"
                          className="form-control cust__form"
                          id=""
                          {...formik.getFieldProps("lastName")}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <div className="form_errors">
                            {formik.errors.lastName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <b>
                          <label for="" className="form-label">
                            Age
                          </label>
                        </b>
                        <input
                          type="number"
                          name="age"
                          className="form-control cust__form"
                          id=""
                          {...formik.getFieldProps("age")}
                        />
                        {formik.touched.age && formik.errors.age && (
                          <div className="form_errors">{formik.errors.age}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <b>
                          <label for="" className="form-label">
                            Gender
                          </label>
                        </b>

                        <select
                          className="form-control cust__form"
                          name="gender"
                          value={gender}
                          onChange={handleGenderSelect}
                          {...formik.getFieldProps("gender")}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">FeMale</option>
                        </select>
                        {formik.touched.gender && formik.errors.gender && (
                          <div className="form_errors">
                            {formik.errors.gender}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <b>
                          <label for="" className="form-label">
                            Email ID
                          </label>
                        </b>
                        <input
                          type="email"
                          className="form-control cust__form"
                          id=""
                          {...formik.getFieldProps("email")}
                        />
                      </div>
                      {formik.touched.email && formik.errors.email && (
                        <div className="form_errors">{formik.errors.email}</div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="mb-2">
                        <b>
                          <label for="" className="form-label">
                            Mobile
                          </label>
                        </b>
                        <input
                          type="text"
                          className="form-control cust__form"
                          id=""
                          {...formik.getFieldProps("contactNumber")}
                        />
                      </div>
                      {formik.touched.contactNumber &&
                        formik.errors.contactNumber && (
                          <div className="form_errors">
                            {formik.errors.contactNumber}
                          </div>
                        )}
                    </div>
                    <br></br>

                    {reschedule ? (
                      <>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <b>
                              <label for="" className="form-label">
                                Date
                              </label>
                            </b>
                            <br></br>
                            <DateInput
                              selectableDates={selectableDates}
                              onDateChange={handleDateChange}
                              name="date"
                              value={selectedDate}
                              {...formik.getFieldProps("date")}
                            />
                            {formik.touched.date && formik.errors.date && (
                              <div className="form_errors">
                                {formik.errors.date}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-2">
                            <b>
                              <label for="" className="form-label">
                                Time
                              </label>
                            </b>
                            {DoctorTimeSlots.length > 0 ? (
                              <>
                                <select
                                  name="time"
                                  className="form-select cust__form"
                                  aria-label="Default select example"
                                  onChange={() => handleSelectedTimeSlot}
                                  {...formik.getFieldProps("time")}
                                >
                                  <option value="">Select Time Slot</option>
                                  {DoctorTimeSlots}
                                </select>
                                {formik.touched.time && formik.errors.time && (
                                  <div className="form_errors">
                                    {formik.errors.time}
                                  </div>
                                )}
                              </>
                            ) : (
                              <p>Note: Please select date</p>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <b>
                              <label for="" className="form-label">
                                Date
                              </label>
                            </b>
                            <input
                              type="text"
                              className="form-control cust__form"
                              id=""
                              value={formatDate(selectedDate)}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-2">
                            <b>
                              <label for="" className="form-label">
                                Time
                              </label>
                            </b>
                            <input
                              type="text"
                              className="form-control cust__form"
                              id=""
                              value={time}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="mb-2">
                            <br></br>
                            <button
                              className="btn save-btn btn_change"
                              onClick={() => setReschedule(true)}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    <br></br>

                    <div className="col-md-4">
                      <div>
                        <b>
                          <label for="" className="form-label">
                            Clinc :
                          </label>
                        </b>
                        <input
                          type="text"
                          className="form-control cust__form"
                          id=""
                          value={appointment?.clinic?.["name"] || "N/A"}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <b>
                          <label for="" className="form-label">
                            Consultation Type :
                          </label>
                        </b>
                        <input
                          type="text"
                          className="form-control cust__form"
                          id=""
                          value={
                            appointment?.consultationType == "1"
                              ? "Online"
                              : "Physical"
                          }
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-2">
                        <b>
                          <label for="" className="form-label">
                            Do you have any serious medical history? if yes
                            please specify
                          </label>
                        </b>
                        <textarea
                          name="patientHistory"
                          className="form-control cust__form"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          {...formik.getFieldProps("patientHistory")}
                        ></textarea>
                      </div>
                      {formik.touched.patientHistory &&
                        formik.errors.patientHistory && (
                          <div className="form_errors">
                            {formik.errors.patientHistory}
                          </div>
                        )}
                    </div>

                    <div className="col-md-12 d-flex justify-content-between">
                      <Link to="/patient/appointments">
                        {" "}
                        <button className="btn cancel-btn">Back</button>
                      </Link>
                      <button type="submit" className="btn save-btn">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default PatientEditAppointment;
