import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";
import TableCell from "../../../components/table/TableCell";
import TableBody from "../../../components/table/TableBody";
import TableRow from "../../../components/table/TableRow";
import TableFooter from "../../../components/table/TableFooter";
import TableContainer from "../../../components/table/TableContainer";
import LoadingDots from "../../../components/LoadingDots";
import ToastConfirmation from "../../../components/toastrConfirmation";
import { capitalizeFirstChar } from "../../../helper/utils";
import Button from "../../../components/Button";
import { Link, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../../components/DefaultAvatar";
import Pagination from "../../../components/Pagination";
import { axiosInstance } from "../../../common/constants";
import SectionTitle from "../../../components/SectionTitle";
import { ToastContainer, toast } from "react-toastify";
// Sevices
import { getList, deleteUser } from "../../../services/admin/servicePatient";

const List = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  let { url } = useRouteMatch();

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p);
  }

  useEffect(() => {
    fetchData();
  }, [pageTable]);

  async function fetchData(keywords = "") {
    try {
      const res = await getList(pageTable, resultsPerPage, keywords);
      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        setDataTable(res.data.patients);
        setTotalResults(res.data.count);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  // ----------------------------------- Searh user
  const handleKeyUp = (event) => {
    setPageTable(0);
    let _keyword = event.target.value;
    fetchData(_keyword);
  };
  // ----------------------------------- Delte user
  const handleConfirmation = (event) => {
    const _userId = event.target.id;
    toast(
      <ToastConfirmation
        prop1={_userId}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const _userId = event.target.dataset.userid;
    // Perform the action
    setLoading(true);
    let _data = {
      uid: _userId,
    };
    const response = await deleteUser(_data);

    if (response.status == 0) {
      toast.error(response.message);
    }
    if (response.status == 1) {
      toast.success(response.message);
      fetchData();
    }
    setLoading(false);
  };

  const handleCancel = () => {
    toast.dismiss();
  };
  //--------------------------------------------- RENDER HTML --------------------
  return (
    <>
      <SectionTitle>Patients </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 p-0">
            <div class="mb-3">
              <input
                onKeyUp={handleKeyUp}
                type="search"
                class="form-control cust__form"
                id=""
                placeholder="Search by name and email"
              />
            </div>
          </div>
          <div className="col-md-8 p-0">
            <div className="mb-3">
              <a href="/admin/patient/create">
                <button class="btn btn__add__appointment">Add Patient</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>Name</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Action</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {dataTable.length > 0
                ? dataTable.map((value, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <div>
                          <p className="font-semibold">{`${value.user?.firstName} ${value.user?.lastName}`}</p>
                          <span className="list-email">
                            {value.user?.email}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <p className="font-semibold">
                          {value.user?.contactNumber}
                        </p>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center text-sm">
                          <p className="font-semibold">{value.user?.age}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center text-sm">
                          <p className="font-semibold">
                            {capitalizeFirstChar(value.user?.gender)}
                          </p>
                        </div>
                      </TableCell>
                      {/* <TableCell>
                                        {value.verified ?
                                            <span
                                                className="px-3 py-1  text-sm rounded-full text-green-600  bg-green-200">
                                                Verified
                                            </span>
                                            :
                                            <span
                                                className="px-3 py-1  text-sm rounded-full text-yellow-600  bg-yellow-200">
                                                Pending Verification
                                            </span>
                                        }

                                    </TableCell> */}
                      <TableCell>
                        <Link
                          to={{
                            pathname: `/admin/patient/view/${value?.uid}`,
                          }}
                        >
                          <Button> View </Button>
                        </Link>
                        <Link
                          to={{
                            pathname: `/admin/patient/edit/${value?.uid}`,
                          }}
                        >
                          <Button> Edit </Button>
                        </Link>

                        <Button id={value.uid} onClick={handleConfirmation}>
                          {" "}
                          Delete{" "}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default List;
