import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  serviceGetAppointmentDetails,
  serviceGetReviewDetails,
} from "../../services/apiService";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import moment from "moment";

const PatientViewAppointment = () => {
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [appointment, setAppointment] = useState("");
  const [reviewData, setReviewData] = useState("");
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  async function getAppointMentDetails(id) {
    try {
      setLoading(true);
      let response = await serviceGetAppointmentDetails(id);
      if (response.status == 1) {
        setSelectedDoctor(response?.data?.doctorID);
        setTime(response?.data?.startTime + "-" + response?.data?.endTime);
        setAppointment(response?.data);
        setLoading(false);
        await getRatings(response?.data?.review?.["id"]);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function getRatings(id) {
    try {
      let response = await serviceGetReviewDetails(id);
      if (response.status == 1) {
        setReviewData(response.data);
      }
    } catch (error) {
      return toast.error(error.message);
    }
  }

  function formatDate(_value) {
    let date = moment(_value);
    let formattedDate = date.format("MM-DD-YYYY");
    return formattedDate;
  }

  function displayStarRating(ratingValue) {
    const maxRating = 5;
    const fullStar = "★";
    const emptyStar = "☆";

    const fullStars = Math.round(ratingValue);
    const emptyStars = maxRating - fullStars;

    const stars = fullStar.repeat(fullStars) + emptyStar.repeat(emptyStars);

    return `${stars}`;
  }

  useEffect(() => {
    getAppointMentDetails(id);
  }, []);

  return (
    <>
      <h3 className="text-2xl font-semibold text-gray-700 dark:text-gray-200 ms-4">
        {`Appointment Details ${"( " + id + " )"}`}
      </h3>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="container-fluid mt-2">
          <>
            <div className="container-fluid">
              <div className="form__box__container">
                <div className="row">
                  <div className="col-md-12">
                    <div class="mb-2">
                      <label className="patient__details__label">
                        Booking No. :
                      </label>
                      <text className="patient__details__value">
                        {appointment?.id || "N/A"}
                      </text>
                      <button
                        className="btn btn-sm ms-3"
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(appointment?.id);
                          toast.success("copied!");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <title>Copy to clipboard</title>
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div class="mb-2">
                      <label className="patient__details__label">
                        Clinic Name :
                      </label>
                      <text className="patient__details__value">
                        {appointment?.clinic?.name || "N/A"}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div class="mb-2">
                      <label className="patient__details__label">
                        Clinic Address :
                      </label>
                      <text className="patient__details__value">
                        {`${appointment?.clinic?.addressLine1 || ""} ${
                          appointment?.clinic?.addressLine2 || ""
                        } ${appointment?.clinic?.city || ""} ${
                          appointment?.clinic?.country || ""
                        } ${appointment?.clinic?.pincode || ""}`}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div class="mb-2">
                      <label className="patient__details__label">
                        Doctor :
                      </label>
                      <text className="patient__details__value">
                        Dr.{" "}
                        {`${appointment?.doctor?.user?.salutation} ${appointment?.doctor?.user?.firstName} ${appointment?.doctor?.user?.middleName} ${appointment?.doctor?.user?.lastName}`}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="mb-2">
                      <label className="patient__details__label">
                        First Name :
                      </label>
                      <text className="patient__details__value">
                        {appointment?.firstName || "N/A"}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="mb-2">
                      <label className="patient__details__label">
                        Last Name :
                      </label>
                      <text className="patient__details__value">
                        {appointment?.lastName || "N/A"}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="mb-2">
                      <label className="patient__details__label">Age : </label>
                      <text className="patient__details__value">
                        {appointment?.age || "N/A"}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="mb-2">
                      <label className="patient__details__label">
                        Gender :
                      </label>
                      <text className="patient__details__value">
                        {appointment?.gender || "N/A"}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="mb-2">
                      <label className="patient__details__label">Email :</label>
                      <text className="patient__details__value">
                        {appointment?.email || "N/A"}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="mb-2">
                      <label className="patient__details__label">
                        Mobile :
                      </label>
                      <text className="patient__details__value">
                        {appointment?.phoneNumber || "N/A"}
                      </text>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label className="patient__details__label">Date :</label>
                      <text className="patient__details__value">
                        {formatDate(appointment?.date) || "N/A"}
                      </text>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label className="patient__details__label">Time :</label>
                      <text className="patient__details__value">
                        {time || "N/A"}
                      </text>
                    </div>
                  </div>
                  {appointment?.consultationType == "1" &&
                  appointment?.videoLink?.length > 0 ? (
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label className="patient__details__label">
                          Consultation Link :
                        </label>
                        <text className="patient__details__value">
                          <a target="_blank" href={appointment?.videoLink}>
                            Click Here
                          </a>
                        </text>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-4">
                    <div className="mb-2">
                      <label className="patient__details__label">
                        Patient History :
                      </label>
                      <text className="patient__details__value">
                        {appointment.patientHistory || "N/A"}
                      </text>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <h5>Rating & Review's</h5>
                    <div class="col-md-12">
                      <label className="patient__details__label">
                        Review :
                      </label>
                      <text className="patient__details__value">
                        {appointment?.review?.review || "N/A"}
                      </text>
                    </div>
                    <br></br>
                    {reviewData.ratings && reviewData.ratings.length > 0
                      ? reviewData.ratings.map((rating) => (
                          <div class="col-md-3">
                            <label className="patient__details__label">
                              {rating.ratingType["name"]} :
                            </label>
                            <text className="patient__details__value">
                              {displayStarRating(rating.rating) || "N/A"}
                            </text>
                          </div>
                        ))
                      : ""}
                  </div>
                  <br></br>
                  <div className="col-md-12 d-flex justify-content-between mt-3">
                    <Link to="/patient/appointments">
                      {" "}
                      <button className="btn cancel-btn">Back</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default PatientViewAppointment;
