const apiCall = require("../Api");

/**
 *
 * @param {*} data
 * @returns
 */
async function serviceGetSignOut() {
  let _response = await apiCall.getData("/api/V1/auth/signOut");
  return _response;
}

async function servicePostSignIn(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/signInWithEmailAndPassword",
    data
  );
  return _response;
}

async function servicePostDoctorRegistration(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/doctor/register",
    data
  );
  return _response;
}

async function servicePostVerifyEmail(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/verifyEmailOTP",
    data
  );
  return _response;
}

async function servicePostPatientRegistration(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/patient/register",
    data
  );
  return _response;
}

async function serviceGetDoctorList(_page, _search, _clinicId) {
  let _response = await apiCall.getData(
    `/api/v1/doctor/listing?page=${_page}&search=${_search}&clinicID=${_clinicId}`
  );
  return _response;
}

async function servicePostBookAppointment(data) {
  let _response = await apiCall.postRawJsonData("/api/V1/appointment/", data);
  return _response;
}

async function serviceGetDoctorAvailability(_doctorId, _clinicID) {
  let _response = await apiCall.getData(
    `/api/V1/doctor/availability?doctorID=${_doctorId}&clinicID=${_clinicID}`
  );
  return _response;
}

async function serviceGetDoctorTimeSlots(_doctorId, _clinicID, _date) {
  let _response = await apiCall.getData(
    `/api/V1/doctor/availability?doctorID=${_doctorId}&clinicID=${_clinicID}&date=${_date}`
  );
  return _response;
}

async function servicePostResetPassword(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/resetPassword",
    data
  );
  return _response;
}

async function servicePostChangeUserPassword(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/changePassword",
    data
  );
  return _response;
}

async function serviceGetAppointments(
  _page,
  status = "",
  patientID = undefined,
  doctorID = undefined
) {
  let url = `/api/V1/patient/appointments?page=${_page}&status=${status}`;
  url += patientID ? `&patientID=${patientID}` : "";
  url += doctorID ? `&doctorID=${doctorID}` : "";
  let _response = await apiCall.getData(url);
  return _response;
}

async function serviceGetAppointmentDetails(_id) {
  let _response = await apiCall.getData(`/api/V1/appointment/?id=${_id}`);
  return _response;
}

async function servicePostEditAppointment(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/appointment/editAppointment",
    data
  );
  return _response;
}

async function serviceGetHomeAppointments() {
  let _response = await apiCall.getData(
    `/api/V1/patient/appointments?dashboard=true`
  );
  return _response;
}

async function serviceGetDoctorAppointments(_date) {
  let _response = await apiCall.getData(
    `/api/V1/doctor/dashboard/appointments?date=${_date}`
  );
  return _response;
}

async function serviceGetDoctorWeeklyAppointments(_date) {
  let _response = await apiCall.getData(
    `/api/V1/doctor/dashboard/appointments/week/?date=${_date}`
  );
  return _response;
}

async function serviceGetDoctorConfiguration(clinicID) {
  let _response = await apiCall.getData(
    `/api/V1/doctor/configuration?clinicID=${clinicID}`
  );
  return _response;
}

async function servicePostDoctorConfiguration(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/doctor/configuration/update",
    data
  );
  return _response;
}

async function serviceGetDoctorAppointmentsById(id) {
  let _response = await apiCall.getData(`/api/V1/appointment?id=${id}`);
  return _response;
}

async function servicePostUpdateAppointmentStatus(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/appointment/changeStatus",
    data
  );
  return _response;
}

async function servicePostSendEmailOTP(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/sendEmailOTP",
    data
  );
  return _response;
}

async function servicePostResendVerificationCode(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/resendEmailOTP",
    data
  );
  return _response;
}

async function serviceGetDoctorDetails(_id) {
  let _response = await apiCall.getData(`/api/v1/doctor/details?uid=${_id}`);
  return _response;
}
async function serviceGetNotifications(pageNo) {
  let _response = await apiCall.getData(
    `/api/V1/notification/getNotifications?page=${pageNo}`
  );
  return _response;
}

async function serviceGetNotificationCount() {
  let _response = await apiCall.getData(
    `/api/V1/notification/getNotificationCount`
  );
  return _response;
}

async function serviceUpdateNotificationStatus(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/notification/updateNotificationStatus",
    data
  );
  return _response;
}

async function serviceGetDoctorReviews(_id) {
  let _response = await apiCall.getData(
    `/api/v1/doctor/reviews?doctorID=${_id}`
  );
  return _response;
}

async function serviceGetRatingTypes() {
  let _response = await apiCall.getData(`/api/V1/reviews/getRatingTypes`);
  return _response;
}

async function servicePostReview(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/appointment/postReview",
    _data
  );
  return _response;
}

async function serviceUpdateReview(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/patient/updateReview",
    _data
  );
  return _response;
}

async function serviceGetReviewDetails(_id) {
  let _response = await apiCall.getData(
    `/api/V1/reviews/getReviewDetails?reviewID=${_id}`
  );
  return _response;
}

async function serviceGetClinicList(_page, _search, _listAll = false) {
  let _response = await apiCall.getData(
    `/api/V1/clinic/list?page=${_page}&search=${_search}&listAll=${_listAll}`
  );
  return _response;
}

async function serviceGetMasterSpecialityList(_search) {
  let _response = await apiCall.getData(
    `/api/V1/specialty/listMasterSpecialties?search=${_search}`
  );
  return _response;
}

async function serviceGetOtpTimer() {
  let _response = await apiCall.getData(`/api/V1/globalConfig/otpTimer`);
  return _response;
}
async function serviceListDoctorClinics(doctorID) {
  let _response = await apiCall.getData(
    `/api/V1/doctor/clinic/list?doctorID=${doctorID}`
  );
  return _response;
}

async function serviceGetPatientSpecialities() {
  let _response = await apiCall.getData(
    `/api/V1/specialty/listMasterSpecialties`
  );
  return _response;
}

async function serviceGetPatientClinicList(_id, _page, _search) {
  let _response = await apiCall.getData(
    `/api/V1/specialty/clinic/list?specialtyID=${_id}&page=${_page}&search=${_search}`
  );
  return _response;
}

async function serviceGetPatientClinicDetails(_id) {
  let _response = await apiCall.getData(`/api/V1/clinic/details?uid=${_id}`);
  return _response;
}

async function servicePatientGetClinicDoctors(_page, _search, _clinicID) {
  let _response = await apiCall.getData(
    `/api/v1/clinic/doctors?page=${_page}&search=${_search}&clinicID=${_clinicID}`
  );
  return _response;
}

async function serviceGetAccessTokenDetails() {
  let _response = await apiCall.getData(`/api/V1/auth/getAccessTokenDetails`);
  return _response;
}

async function servicePatientCancelAppointment(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/patient/cancelAppointment",
    _data
  );
  return _response;
}

async function servicePatientPostMedicalHistory(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/medicalHistory/create",
    _data
  );
  return _response;
}

async function servicePatientGetMedicalHistory(_patientId) {
  let _response = await apiCall.getData(
    `/api/v1/medicalHistory?patientID=${_patientId}`
  );
  return _response;
}

async function serviceDoctorGetMemoAppointmentList(_doctorID, _search, _page) {
  let _response = await apiCall.getData(
    `/api/V1/appointment/list?doctorID=${_doctorID}&status=3&search=${
      _search || ""
    }&page=${_page}`
  );
  return _response;
}

async function servicePatientMedicalHistoryList(patientID) {
  let _response = await apiCall.getData(
    `/api/V1/medicalHistory/list?patientID=${patientID}`
  );
  return _response;
}

async function servicePostMemoExamination(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/examination/create",
    _data
  );
  return _response;
}

async function servicePostMemoDignosis(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/diagnosis/update",
    _data
  );
  return _response;
}

async function servicePostMemoTreatmentPlan(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/treatmentPlan/update",
    _data
  );
  return _response;
}

async function servicePostMemoFollowups(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/followupInstruction/update",
    _data
  );
  return _response;
}

async function servicePostMemoReferral(_data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/referral/update",
    _data
  );
  return _response;
}

async function serviceGetMemoExamination(_appointmentID) {
  let _response = await apiCall.getData(
    `/api/V1/examination/list?appointmentID=${_appointmentID}`
  );
  return _response;
}

async function serviceGetMemoDignosis(_appointmentID) {
  let _response = await apiCall.getData(
    `/api/V1/diagnosis/list?appointmentID=${_appointmentID}`
  );
  return _response;
}

async function serviceGetMemoFollowups(_appointmentID) {
  let _response = await apiCall.getData(
    `/api/V1/followupInstruction/list?appointmentID=${_appointmentID}`
  );
  return _response;
}

async function serviceGetMemoReferrel(_appointmentID) {
  let _response = await apiCall.getData(
    `/api/V1/referral/list?appointmentID=${_appointmentID}`
  );
  return _response;
}

async function serviceGetTreatmentPlan(_appointmentID) {
  let _response = await apiCall.getData(
    `/api/V1/treatmentPlan/list?appointmentID=${_appointmentID}`
  );
  return _response;
}

async function servicePostSendMemo(_data) {
  let _response = await apiCall.postRawJsonData("/api/V1/memos/create", _data);
  return _response;
}

export {
  serviceGetSignOut,
  servicePostSignIn,
  servicePostSendEmailOTP,
  servicePostResetPassword,
  servicePostDoctorRegistration,
  servicePostVerifyEmail,
  servicePostPatientRegistration,
  serviceGetDoctorList,
  servicePostBookAppointment,
  serviceGetDoctorAvailability,
  serviceGetDoctorTimeSlots,
  servicePostChangeUserPassword,
  serviceGetAppointments,
  serviceGetAppointmentDetails,
  servicePostEditAppointment,
  serviceGetHomeAppointments,
  serviceGetDoctorAppointments,
  serviceGetDoctorConfiguration,
  servicePostDoctorConfiguration,
  serviceGetDoctorAppointmentsById,
  servicePostUpdateAppointmentStatus,
  servicePostResendVerificationCode,
  serviceGetRatingTypes,
  servicePostReview,
  serviceGetDoctorDetails,
  serviceGetDoctorReviews,
  serviceGetNotifications,
  serviceGetNotificationCount,
  serviceUpdateNotificationStatus,
  serviceUpdateReview,
  serviceGetReviewDetails,
  serviceGetClinicList,
  serviceGetOtpTimer,
  serviceGetMasterSpecialityList,
  serviceListDoctorClinics,
  serviceGetPatientSpecialities,
  serviceGetPatientClinicList,
  serviceGetPatientClinicDetails,
  servicePatientGetClinicDoctors,
  serviceGetAccessTokenDetails,
  servicePatientCancelAppointment,
  serviceGetDoctorWeeklyAppointments,
  servicePatientPostMedicalHistory,
  servicePatientGetMedicalHistory,
  serviceDoctorGetMemoAppointmentList,
  servicePostMemoExamination,
  servicePostMemoDignosis,
  servicePostMemoTreatmentPlan,
  servicePostMemoFollowups,
  servicePostMemoReferral,
  serviceGetMemoExamination,
  serviceGetMemoDignosis,
  serviceGetMemoFollowups,
  serviceGetMemoReferrel,
  serviceGetTreatmentPlan,
  servicePostSendMemo,
  servicePatientMedicalHistoryList,
};
