import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../healthlink_logo.svg";
import LoadingSpinner from "../../components/LoadingSpinner";
import { servicePostPatientRegistration } from "../../services/apiService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const PatientRegister = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [salutation, setSalutation] = useState("Mr");

  function onAlertCloseClick() {
    setError("");
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    contactNumber: Yup.string()
      .min(5, "Mobile number must be at least 5 digits")
      .max(20, "Mobile number must not exceed 20 digits")
      .required("Mobile number is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 Characters"),
    passwordConfirm: Yup.string().required("Confirm password is required"),
    terms: Yup.string().required("Please Accept Terms and Conditions"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      password: "",
      passwordConfirm: "",
      terms: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = {
        salutation: salutation,
        firstName: _values.firstName,
        middleName: _values.middleName || "",
        lastName: _values.lastName,
        email: _values.email,
        contactNumber: _values.contactNumber,
        password: _values.password,
        gender: salutation == "Mr" ? "Male" : "Female",
      };
      if (_values.password != _values.passwordConfirm) {
        setLoading(false);
        return toast.error("Password and Confirm password do not match");
      }
      const registerData = await servicePostPatientRegistration(data);

      if (registerData.status == 0) {
        toast.error(registerData.message);
        setLoading(false);
      }
      if (registerData.status == 1) {
        history.replace("/");
        history.push("/verify-email/patient/" + registerData.data.credentialID);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  }

  const handleSalutationChange = (event) => {
    setSalutation(event.target.value);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="min-h-full">
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-12">
                <Link to="/" className="flex items-center justify-center">
                  <img src={logo} className="h-10 w-10 mr-2" alt="logo" />
                  <span className="font-extrabold text-indigo-600 text-3xl">
                    HealthLink <sub className="text-base font-bold">Doctor</sub>
                  </span>
                </Link>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                  Patient Registration
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Or{" "}
                  <Link
                    to="/patientLogin"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    log In with an existing account
                  </Link>
                </p>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} action="#" method="POST">
              <div className="row justify-content-center mb-3">
                <div className="col-md-1">
                  <label className="form-label">First Name</label>

                  <select
                    class="form-control cust__form"
                    name="salutation"
                    value={salutation}
                    onChange={handleSalutationChange}
                  >
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label className="form-label">
                    {" "}
                    <p></p>
                  </label>
                  <input
                    type="text"
                    {...formik.getFieldProps("firstName")}
                    className="form-control cust__form"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="form_errors">{formik.errors.firstName}</div>
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">Middle Name</label>
                  <input
                    type="text"
                    name="middleName"
                    className="form-control cust__form"
                    {...formik.getFieldProps("middleName")}
                  />
                </div>
              </div>

              <div className="row justify-content-center mb-3">
                <div className="col-md-4">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    {...formik.getFieldProps("lastName")}
                    className="form-control cust__form"
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="form_errors">{formik.errors.lastName}</div>
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">Email ID</label>
                  <input
                    type="email"
                    className="form-control cust__form"
                    ref={emailRef}
                    id="emailRef"
                    name="emailRef"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    title="Please enter only lowercase letters"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="form_errors">{formik.errors.email}</div>
                  )}
                </div>
              </div>

              <div className="row justify-content-center mb-3">
                <div className="col-md-4">
                  <label className="form-label">Mobile</label>
                  <div className="form-control cust__form">
                    <PhoneInput
                      className={"input-phone-number"}
                      placeholder="Enter phone number"
                      name="contactNumber"
                      value={contactNumber}
                      onChange={(e) => formik.setFieldValue("contactNumber", e)}
                    />
                  </div>
                  {formik.touched.contactNumber &&
                    formik.errors.contactNumber && (
                      <div className="form_errors">
                        {formik.errors.contactNumber}
                      </div>
                    )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control cust__form"
                    ref={passwordRef}
                    id="passwordRef"
                    name="passwordRef"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="form_errors">{formik.errors.password}</div>
                  )}
                </div>
              </div>
              <div className="row justify-content-center mb-3">
                <div className="col-md-4">
                  <label className="form-label">Confirm Password</label>
                  <input
                    type="password"
                    className="form-control cust__form"
                    ref={passwordConfirmRef}
                    id="passwordConfirm"
                    name="passwordConfirm"
                    {...formik.getFieldProps("passwordConfirm")}
                  />
                  {formik.touched.passwordConfirm &&
                    formik.errors.passwordConfirm && (
                      <div className="form_errors">
                        {formik.errors.passwordConfirm}
                      </div>
                    )}
                </div>
                <div className="col-md-4"></div>
              </div>
              <div className="row justify-content-center mb-3">
                <div className="col-md-4"></div>
              </div>
              <div className="row justify-content-center mb-3">
                <div className="col-md-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="terms"
                    {...formik.getFieldProps("terms")}
                  />
                  <label className="form-check-label ml-3" for="exampleCheck1">
                    I Agree To Terms and Condition
                  </label>
                  {formik.touched.terms && formik.errors.terms && (
                    <div className="form_errors">{formik.errors.terms}</div>
                  )}
                </div>
                <div className="col-md-4"></div>
              </div>
              <div className="row justify-content-end mb-3">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <button className="btn save-btn"> Save</button>
                  <Link to="/">
                    {" "}
                    <button className="btn cancel-btn ml-3">Back</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="max-w-md w-full space-y-8">
            <div></div>
            {error && (
              <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-600">
                <span className="inline-block align-middle mr-8">
                  <b className="capitalize">Error:</b> {error}
                </span>
                <button
                  onClick={onAlertCloseClick}
                  className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                >
                  <span>×</span>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PatientRegister;
