import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";
import TableCell from "../../../components/table/TableCell";
import TableBody from "../../../components/table/TableBody";
import TableRow from "../../../components/table/TableRow";
import TableFooter from "../../../components/table/TableFooter";
import TableContainer from "../../../components/table/TableContainer";
import LoadingDots from "../../../components/LoadingDots";
import ToastConfirmation from "../../../components/toastrConfirmation";
import Button from "../../../components/Button";
import { Link, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../../components/DefaultAvatar";
import Pagination from "../../../components/Pagination";
import { axiosInstance } from "../../../common/constants";
import SectionTitle from "../../../components/SectionTitle";
import { ToastContainer, toast } from "react-toastify";
import RecordPlaceholder from "../../../components/RecordPlaceholder";

// Sevices
import {
  getReviewList,
  hideReview,
  getDoctorsList,
} from "../../../services/admin/serviceAdmin";
import DoctorRatingAndReview from "../../../services/doctor/ratingAndReview.service";
import EyeIcon from "../../../dashboard/sidenavigation/icons/eye";
import DoctorReviewAndRatingModel from "../../../components/doctor/DoctorReviewAndRatingModel";
import SearchIcon from "../../../dashboard/sidenavigation/icons/search";
import { formatDate } from "../../../components/FormatDate";

const DoctorReviewAndRatingList = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [_doctors, setDoctors] = useState({});
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedRating, setSelectedRating] = useState(null);

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p - 1);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(search = "") {
    try {
      const res = await DoctorRatingAndReview.getRatingsAndReviews(
        pageTable,
        search
      );
      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        if (res.data) {
          let _data = res.data;
          res.data.forEach((element, index) => {
            let rating = 0;

            if (element?.ratings && element?.ratings.length != 0) {
              var _elementLenght = element?.ratings.length;
              for (let i = 0; i < element?.ratings.length; i++) {
                rating += element?.ratings[i].rating;
              }
              let _average = rating / _elementLenght;
              _data[index].avgRating = _average;
            } else {
              _data[index].avgRating = 0;
            }
          });
        }
        setDataTable(res.data);
        setTotalResults(res.totalCount);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  function displayStarRating(ratingValue) {
    const maxRating = 5;
    const fullStar = "★";
    const emptyStar = "☆";

    const fullStars = Math.round(ratingValue);
    const emptyStars = maxRating - fullStars;

    const stars = fullStar.repeat(fullStars) + emptyStar.repeat(emptyStars);

    return `${stars}`;
  }

  //   Function to handle opening the modal and setting the selected doctor
  const openModal = (ratingAndReview) => {
    setSelectedRating(ratingAndReview);
    setShowModal(!showModal);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Debounce at searching
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 3000);
    };
  };

  const searchReview = (event) => {
    let search = document.getElementById("search").value;
    setLoading(true);
    fetchData(search);
  };

  //--------------------------------------------- RENDER HTML --------------------
  return (
    <>
      <SectionTitle>Ratings And Reviews</SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div class="col-6 mb-6 ps-0">
            <input
              type="search"
              class="form-control cust__form"
              id="search"
              placeholder="Search"
              // onKeyUp={(event) => searchReview(event)}
            />
          </div>
          <div class="col-6 mb-6">
            <button
              type="button"
              class="btn d-flex"
              style={{ background: "#4F46E5", color: "white" }}
              onClick={() => searchReview()}
            >
              <SearchIcon />
              <p className="m-0">Search</p>
            </button>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>Patient</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Review</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {dataTable && dataTable?.length > 0 ? (
                dataTable.map((value, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <div>
                        <p className="font-semibold">{`${value.patient?.firstName} ${value.patient?.lastName}`}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold">
                        {displayStarRating(value.avgRating)}
                      </p>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <p className="font-semibold">
                          {value.review.substring(0, 40)}
                          {value.review.length > 40 ? "..." : ""}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <p className="font-semibold">
                          {formatDate(value.createdAt)}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <button
                        className="p-1"
                        style={{ color: "blue" }}
                        onClick={() => openModal(value)}
                      >
                        <EyeIcon />
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
      <DoctorReviewAndRatingModel
        review={selectedRating}
        closeModal={closeModal}
        modalIsOpen={showModal}
      />
    </>
  );
};

export default DoctorReviewAndRatingList;
