import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  servicePostMemoDignosis,
  serviceGetMemoDignosis,
} from "../../services/apiService";
import LoadingSpinner from "../../components/LoadingSpinner";

const MemoDignosis = ({ patientId, appointmentId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { userData } = useAuth();

  const validationSchema = Yup.object({
    medicalCondition: Yup.string().required("Medical condition is required"),
    severityStatus: Yup.string().required("Severiaty status is required"),
  });

  const formik = useFormik({
    initialValues: {
      patientID: patientId,
      doctorID: userData.uid,
      appointmentID: appointmentId,
      medicalCondition: "",
      severityStatus: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await serviceGetMemoDignosis(appointmentId);

      if (response.status == 0) {
        setLoading(false);
        toast.error(response.message);
      }
      if (response.status == 1) {
        setLoading(false);
        const dignosisData = response?.data?.diagnosis;
        const newValues = {
          patientID: patientId,
          doctorID: userData.uid,
          appointmentID: appointmentId,
          medicalCondition: dignosisData[0]?.medicalCondition || "",
          severityStatus: dignosisData[0]?.severityStatus || "",
        };
        formik.setValues(newValues);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = _values;
      const response = await servicePostMemoDignosis(data);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        toast.success(response.message);
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="gap-3 setting__form">
            <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
              <form
                onSubmit={formik.handleSubmit}
                action="#"
                method="POST"
                className="col-span-2"
              >
                <div className="form__box__container">
                  <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Medical Condition</b>
                      </label>
                      <input
                        type="text"
                        className="form-control cust__form"
                        placeholder="Medical Condition"
                        {...formik.getFieldProps("medicalCondition")}
                      />

                      {formik.touched.medicalCondition &&
                        formik.errors.medicalCondition && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.medicalCondition}
                          </p>
                        )}
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Severity Status</b>
                      </label>
                      <input
                        type="text"
                        className="form-control cust__form"
                        placeholder="Severity Status"
                        {...formik.getFieldProps("severityStatus")}
                      />

                      {formik.touched.severityStatus &&
                        formik.errors.severityStatus && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.severityStatus}
                          </p>
                        )}
                    </div>
                  </div>
                  <br></br>
                  <div style={{ marginLeft: "45%" }}>
                    <button type="submit" className="setting__doctor__save">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemoDignosis;
