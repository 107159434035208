import { useEffect, useState } from "react";
import LoadingDots from "../../components/LoadingDots";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
import Table from "../../components/table/Table";
import TableRow from "../../components/table/TableRow";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import RecordPlaceholder from "../../components/RecordPlaceholder";
import {
  serviceGetAppointmentDetails,
  serviceGetAppointments,
  servicePatientMedicalHistoryList,
} from "../../services/apiService";
import { Link } from "react-router-dom";
import TableContainer from "../../components/table/TableContainer";
import { useParams } from "react-router-dom";
import DoctorGenerateMemoModal from "../../components/doctor/DoctorGenerateMemoModal";
import PencilIcon from "../../dashboard/sidenavigation/icons/pencil";
import TableHeader from "../../components/table/TableHeader";
import { getPatientDetails } from "../../services/admin/servicePatient";
import { FaEnvelope, FaPhone, FaDownload } from "react-icons/fa";
import React from "react";

const DoctorPatientDetails = () => {
  const [loading, setLoading] = useState(true);
  const [memoLoading, setmemoLoading] = useState(false);
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [selectedID, setSelectedID] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [medicalHistory, setMedicalHistory] = useState(null);
  const [generateMemo, setGenerateMemo] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [patientDataLoading, setPatientDataLoading] = useState(null);
  const [followupAppointments, setFollowupAppointments] = useState(null);
  const [todayChecked, setTodayChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(true);
  const { userData } = useAuth();
  const { patientID } = useParams();
  const displayedDates = [];

  useEffect(() => {
    setDataTable([]);
    getMedicalHistory();
    fetchPatientDetails();
  }, []);

  useEffect(() => {
    getAppointmentList();
  }, [page]);

  useEffect(() => {
    getAppointmentDetails();
  }, [selectedID]);

  // this.state = {
  //   todayChecked: false,
  //   allChecked: true, // Assuming you want "All" to be initially checked
  // };

  const handleTodayChange = () => {
    setTodayChecked(!todayChecked);
    setAllChecked(false);
  };

  const handleAllChange = () => {
    setAllChecked(!allChecked);
    setTodayChecked(false);
  };

  // Define a function to filter appointments based on checkbox status
  const filterAppointments = (appointments) => {
    if (allChecked) {
      return appointments;
    } else if (todayChecked) {
      const today = new Date().toDateString();
      return appointments.filter((el) =>
        el?.attachments.some(
          (attachment) =>
            new Date(attachment.createdAt).toDateString() === today
        )
      );
    } else {
      return [];
    }
  };

  const filteredAppointments = filterAppointments(
    selectedAppointment?.patientMemo
  );

  let latestAttachment = null;

  if (filteredAppointments && Array.isArray(filteredAppointments)) {
    filteredAppointments.forEach((el) => {
      if (el && el.attachments && Array.isArray(el.attachments)) {
        el.attachments.forEach((attachment) => {
          const attachmentDate = new Date(attachment.createdAt.toString());
          if (!latestAttachment || attachmentDate > latestAttachment.date) {
            latestAttachment = { date: attachmentDate, attachment };
          }
        });
      }
    });
  } else {
    console.error("filteredAppointments is not an array or is null/undefined.");
  }

  async function getAppointmentList() {
    try {
      setAppointmentLoading(true);
      let response = await serviceGetAppointments(
        page,
        3,
        patientID,
        userData.uid
      );
      if (response.status == 1) {
        setDataTable([...dataTable, ...response.data]);
        setTotalResults(response.totalCount);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setAppointmentLoading(false);
    }
  }

  async function getMedicalHistory() {
    try {
      setLoading(true);
      let response = await servicePatientMedicalHistoryList(patientID);
      if (response.status == 1) {
        setMedicalHistory(response.data[0]);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function fetchPatientDetails() {
    try {
      setPatientDataLoading(true);
      let response = await getPatientDetails(patientID);
      if (response.status == 1) {
        setPatientData(response.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPatientDataLoading(false);
    }
  }

  async function getAppointmentDetails() {
    try {
      if (!selectedID) {
        return;
      }
      setmemoLoading(true);
      let response = await serviceGetAppointmentDetails(selectedID);
      if (response.status == 1) {
        setSelectedAppointment(response.data);
        setmemoLoading(false);
        if (
          response.data?.followupInstruction &&
          response.data?.followupInstruction?.length > 0
        ) {
          const followupAppointments = response.data.followupInstruction.map(
            (el) => new Date(el.nextAppointmentDate).toLocaleDateString()
          );
          setFollowupAppointments(followupAppointments);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setmemoLoading(false);
    }
  }

  return !loading ? (
    <>
      <section className="medical__history__section">
        <div className="container-fluid">
          <div className="row">
            <div>
              <h1 style={{ fontSize: "12px" }}>
                {" "}
                <a href="/doctor/patients-list">Your Patients </a> &gt; Memos
              </h1>
            </div>
            <br />
            <h3>Memos</h3>
            <div className="col-md-5">
              <div className="card__medical__history__box">
                <div className="card__profile__mh__box">
                  <div className="profile__history__img__mh">
                    {patientData?.user?.avatarImageUrl ? (
                      <img
                        src={`${patientData?.user?.avatarImageUrl}`}
                        alt="Profile Image"
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </div>
                  <div className="profile__history__name__mh">
                    {!patientDataLoading ? (
                      <>
                        <h2>
                          {patientData?.user?.salutation}{" "}
                          {patientData?.user?.firstName}{" "}
                          {patientData?.user?.lastName}
                        </h2>
                        <div>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FaEnvelope style={{ marginRight: "5px" }} />
                            {patientData?.user?.email}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FaPhone style={{ marginRight: "5px" }} />
                            {patientData?.user?.contactNumber}
                          </span>
                        </div>
                      </>
                    ) : (
                      <LoadingDots></LoadingDots>
                    )}
                  </div>
                </div>
                <hr />
                <div className="medical__condition__box">
                  <p>
                    <b>Medical Condition:</b>{" "}
                    <span>{medicalHistory?.medicalCondition}</span>
                  </p>
                  <p>
                    <b>Previous Surgeries:</b>{" "}
                    <span>{medicalHistory?.surgeries}</span>
                  </p>
                  <p>
                    <b>Description:</b>{" "}
                    <span>{medicalHistory?.description}</span>
                  </p>
                </div>
                <hr />
                <div className="medical__condition__box">
                  <div className="medical__history__head__box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    <p className="ms-2">Booking History</p>
                  </div>

                  <div style={{ height: "45vh", overflowY: "scroll" }}>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {dataTable && dataTable.length > 0 ? (
                            dataTable.map((el) => (
                              <TableRow>
                                <TableCell>
                                  <div
                                    className={`p-3 border row ${
                                      el.id == selectedID ? "selected" : ""
                                    }`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setSelectedID(el.id)}
                                  >
                                    <div className="col-lg-12">
                                      <b>
                                        <h1 style={{ fontSize: "16px" }}>
                                          {" "}
                                          Booking Id:{" "}
                                        </h1>
                                      </b>

                                      <span style={{ fontSize: "16px" }}>
                                        {" "}
                                        {el.id}{" "}
                                      </span>
                                    </div>

                                    <div
                                      className="col-lg-12"
                                      title={el?.clinic?.name}
                                    >
                                      <br />
                                      <b>
                                        <h1 style={{ fontSize: "16px" }}>
                                          {" "}
                                          Clinic Name: &nbsp; {
                                            el?.clinic?.name
                                          }{" "}
                                        </h1>
                                      </b>
                                    </div>

                                    <div
                                      className="col-lg-6"
                                      title={new Date(
                                        el?.date
                                      ).toLocaleDateString()}
                                    >
                                      <h6 style={{ fontSize: "16px" }}>
                                        {" "}
                                        Date: &nbsp;{" "}
                                        {new Date(
                                          el?.date
                                        ).toLocaleDateString()}{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : !appointmentLoading ? (
                            <RecordPlaceholder></RecordPlaceholder>
                          ) : (
                            ""
                          )}
                          {appointmentLoading ? (
                            <TableRow>
                              <LoadingDots />
                            </TableRow>
                          ) : (
                            ""
                          )}
                          {dataTable.length > 0 &&
                          dataTable.length < totalResults ? (
                            <TableRow style={{ border: "none" }}>
                              <div className="text-end" color="primary">
                                <button
                                  className="mt-2"
                                  onClick={() => setPage(page + 1)}
                                >
                                  <h5>Load more...</h5>
                                </button>
                              </div>
                            </TableRow>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
            {!memoLoading ? (
              selectedAppointment ? (
                selectedAppointment?.canSendMemo == 1 ? (
                  <div className="col-md-7">
                    <div className="medical__history__details__box">
                      <div className="memo__container__db">
                        {latestAttachment && (
                          <div>
                            <a
                              href={`${process.env.REACT_APP_LOCAL_API_URL}${latestAttachment.attachment.documentURL}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {latestAttachment.attachment.documentURL
                                .split("/")
                                .pop()}
                            </a>
                          </div>
                        )}

                        <br />
                        <div className="medical__history__details__doc">
                          <p
                            className="col-lg-3 text-overflow"
                            style={{ textAlign: "left", fontSize: "14px" }}
                          >
                            {new Date(
                              selectedAppointment?.date
                            ).toLocaleDateString()}
                          </p>
                          <p
                            className="col-lg-9 text-overflow"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              title="Edit Memo"
                              className="btn btn-link d-flex"
                              onClick={() => setGenerateMemo(true)}
                            >
                              <PencilIcon />
                              &nbsp;&nbsp;Edit&nbsp;&nbsp;Memo
                            </button>
                          </p>
                        </div>

                        <div className="medical__history__details__doc__id">
                          <div className="row">
                            <div className="col-lg-12 text-overflow">
                              Patient Name:{" "}
                              {selectedAppointment?.patient?.user?.salutation}{" "}
                              {selectedAppointment?.patient?.user?.firstName}{" "}
                              {selectedAppointment?.patient?.user?.middleName}{" "}
                              {selectedAppointment?.patient?.user?.lastName}
                            </div>
                          </div>
                        </div>

                        <div className="medical__history__details__doc__id">
                          <div className="row">
                            <div className="col-10">
                              <p>
                                Booking ID:&nbsp;&nbsp;
                                <Link
                                  to={`/patient/viewAppointment/${selectedAppointment?.id}`}
                                  target="_blank"
                                >
                                  {selectedAppointment?.id}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="memo__box__history">
                          <h6>Examination Finding </h6>
                          <p>
                            {`During the visit, ${
                              selectedAppointment?.patient?.user?.firstName
                            } presented with ${
                              selectedAppointment?.examinations[0]
                                ?.reasonToVisit || "none"
                            }. Vital signs were within normal limits, with a blood pressure of ${
                              selectedAppointment?.examinations[0]
                                ?.highBloodPressure || "none"
                            } / ${
                              selectedAppointment?.examinations[0]
                                ?.lowBloodPressure || "none"
                            }, heart rate ${
                              selectedAppointment?.examinations[0]?.heartRate ||
                              "none"
                            }, respiratory rate ${
                              selectedAppointment?.examinations[0]
                                ?.respiratoryRate || "none"
                            }, and temperature ${
                              selectedAppointment?.examinations[0]
                                ?.temperature || "none"
                            }. Physical examination revealed ${
                              selectedAppointment?.examinations[0]
                                ?.physicalDescription || "none"
                            }.`}
                          </p>
                        </div>
                        <hr />
                        <div className="memo__box__history">
                          <h6>Diagnosis : </h6>
                          <p>
                            {`Based on the evaluation, ${
                              selectedAppointment?.patient?.user?.firstName
                            } has been diagnosed with ${
                              selectedAppointment?.examinations[0]
                                ?.reasonToVisit || "none"
                            }, which is ${
                              selectedAppointment?.patientDiagnosis[0]
                                ?.medicalCondition || "none"
                            } ${
                              selectedAppointment?.patientDiagnosis[0] &&
                              selectedAppointment?.patientDiagnosis[0]
                                ?.severityStatus
                                ? "and it is " +
                                  selectedAppointment?.patientDiagnosis[0]
                                    ?.severityStatus +
                                  " severity"
                                : ""
                            }. This diagnosis is consistent with following medications.`}
                          </p>
                          <table
                            className="text-center"
                            style={{ width: "100%", border: "1px solid" }}
                          >
                            <thead>
                              <th style={{ border: "1px solid" }}>Sr.</th>
                              <th style={{ border: "1px solid" }}>Medicine</th>
                              <th style={{ border: "1px solid" }}>Quantity</th>
                              <th style={{ border: "1px solid" }}>Duration</th>
                            </thead>
                            <tbody>
                              {selectedAppointment.treatmentPlan.map(
                                (el, i) => (
                                  <tr>
                                    <td style={{ border: "1px solid" }}>
                                      {i + 1}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {el?.medicine}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {el?.quantity}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {el?.duration}&nbsp;Days
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <hr />
                        <div className="memo__box__history">
                          <h6>Follow-up Instructions : </h6>
                          <p>
                            {`I have advised the patient to schedule a follow-up appointment in ${(
                              followupAppointments || ["NA"]
                            ).join(
                              ","
                            )} to assess the response to the treatment and make any necessary adjustments to the management plan. If there are any concerning developments or if the patient's condition worsens, I have advised them to contact our clinic immediately.`}
                          </p>
                        </div>
                        <hr />
                        <div className="memo__box__history">
                          <h6>Referrals : </h6>
                          <p>
                            {selectedAppointment.referrals
                              .map(
                                (el, i) =>
                                  `${i + 1}. ${
                                    el?.refferedDoctor?.user?.salutation
                                  } ${el?.refferedDoctor?.user?.firstName} ${
                                    el?.refferedDoctor?.user?.middleName
                                  } ${
                                    el?.refferedDoctor?.user?.lastName
                                  }, email - ${
                                    el?.refferedDoctor?.user?.email
                                  }, contact - ${
                                    el?.refferedDoctor?.user?.contactNumber
                                  }\n`
                              )
                              .join("")}
                          </p>
                        </div>
                        <hr />
                        <div className="memo__box__history">
                          <h6>Attachments : </h6>
                          <span style={{ fontSize: "15px" }}>Select Date</span>
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              checked={todayChecked}
                              onChange={handleTodayChange}
                            />
                            {""} Today
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <label>
                            <input
                              type="checkbox"
                              checked={allChecked}
                              onChange={handleAllChange}
                            />
                            {""} All
                          </label>
                          <br />
                          <table
                            className="text-left"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th></th>
                                <th></th>
                                <th>Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredAppointments.map((el, i) =>
                                el.attachments.map((attachment, j) => {
                                  const attachmentDate = new Date(
                                    attachment.createdAt.toString()
                                  ).toLocaleDateString();
                                  // Check if this date has been displayed already
                                  if (
                                    !displayedDates.includes(attachmentDate)
                                  ) {
                                    // If not, display the date
                                    displayedDates.push(attachmentDate);
                                    return (
                                      <React.Fragment key={j}>
                                        <tr>
                                          <td colSpan="4">{attachmentDate}</td>
                                        </tr>
                                        <tr key={j}>
                                          <td>
                                            <span>{attachment.name}</span>
                                          </td>
                                          <td>
                                            <a
                                              href={`${process.env.REACT_APP_LOCAL_API_URL}${attachment.documentURL}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {attachment.documentURL
                                                .split("/")
                                                .pop()}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              href={`${process.env.REACT_APP_LOCAL_API_URL}${attachment.documentURL}`}
                                              target="_blank"
                                              download
                                              rel="noopener noreferrer"
                                            >
                                              <FaDownload />
                                            </a>
                                          </td>
                                          <td>
                                            {new Date(
                                              attachment.createdAt.toString()
                                            ).toLocaleTimeString()}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  } else {
                                    // If the date has already been displayed, omit the date cell

                                    return (
                                      <tr key={j}>
                                        <td>
                                          <span>{attachment.name}</span>
                                        </td>
                                        <td>
                                          <a
                                            href={`${process.env.REACT_APP_LOCAL_API_URL}${attachment.documentURL}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {attachment.documentURL
                                              .split("/")
                                              .pop()}
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href={`${process.env.REACT_APP_LOCAL_API_URL}${attachment.documentURL}`}
                                            target="_blank"
                                            download
                                            rel="noopener noreferrer"
                                          >
                                            <FaDownload />
                                          </a>
                                        </td>
                                        <td>
                                          {new Date(
                                            attachment.createdAt.toString()
                                          ).toLocaleTimeString()}
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              )}
                            </tbody>
                          </table>
                        </div>

                        <hr />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-7 d-flex align-items-center justify-content-center">
                    <div className="medical__history__details__box w-100">
                      <div className="memo__container__db">
                        <div className="medical__history__details__doc">
                          <p className="col-lg-9 text-overflow">
                            Doctor Name: Dr.{" "}
                            {selectedAppointment?.doctor?.user?.salutation}{" "}
                            {selectedAppointment?.doctor?.user?.firstName}{" "}
                            {selectedAppointment?.doctor?.user?.middleName}{" "}
                            {selectedAppointment?.doctor?.user?.lastName}
                          </p>
                          <p className="col-lg-3 text-overflow text-end">
                            {new Date(
                              selectedAppointment?.date
                            ).toLocaleDateString()}
                          </p>
                        </div>
                        <div className="medical__history__details__doc__id">
                          <p>
                            Booking ID:&nbsp;&nbsp;
                            <Link
                              to={`/patient/viewAppointment/${selectedAppointment?.id}`}
                              target="_blank"
                            >
                              {selectedAppointment?.id}
                            </Link>
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-5">
                          <button
                            className="btn btn-primary btn-lg"
                            onClick={() => setGenerateMemo(true)}
                          >
                            Generate Memo
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                ""
              )
            ) : (
              <div className="col-md-7 d-flex align-content-center justify-content-center">
                <LoadingDots />
              </div>
            )}
          </div>
        </div>
      </section>
      {generateMemo ? (
        <>
          <DoctorGenerateMemoModal
            patientId={selectedAppointment?.patientID}
            appointmentId={selectedAppointment?.id}
            patientName={`Dr. ${selectedAppointment?.doctor?.user?.salutation} ${selectedAppointment?.doctor?.user?.firstName} ${selectedAppointment?.doctor?.user?.middleName} ${selectedAppointment?.doctor?.user?.lastName}`}
            closeModal={() => setGenerateMemo(false)}
            modalIsOpen={generateMemo}
          />
        </>
      ) : (
        ""
      )}
    </>
  ) : (
    <LoadingDots />
  );
};

export default DoctorPatientDetails;
