import React, { useRef, useState } from 'react';
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingDots from "../../../components/LoadingDots";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
// Sevices
import { create } from "../../../services/admin/serviceSpecialist";

const Create = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [icon, setIcon] = useState("");
    //------------------------- FUCNCTIONS ------------------
    const goBack = () => {
        history.goBack();
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        icon: Yup.mixed()
            .required(),

    });

    const formik = useFormik({
        initialValues: {
            name: "",
            icon: "",
        },
        validationSchema,
        onSubmit: (values) => {
            webHandleSubmit(values);
        },
    });

    // ---------- SUBMIT THE FORM -----------
    async function webHandleSubmit(_values) {
        try {
            setError("");
            setLoading(true);
            let form = document.getElementById("specialist-form");
            let _form = new FormData(form);
            _form.append("icon", icon);
            const _response = await create(_form);
            setLoading(false);
            if (_response.status == 0) {
                toast.error(_response.message);
            }
            if (_response.status == 1) {
                toast.success(_response.message);
                history.push("/admin/speicialist/list");
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    }

    const onSelectImageHandler = (files) => {
        const file = files[0];
        setIcon(file);
    }

    // ----------------------------------------- RENDER HTML ----------------------------

    return (
        <>
            <div className='container-fluid'>
                <div className="form__box__container">
                    <form onSubmit={formik.handleSubmit} id="specialist-form" action="#" method="POST" enctype="multipart/form-data">
                        <div className="row">
                            <h3 className="head__weekly__report">Create</h3>
                            <div className="col-md-3">
                                <div className="mb-2">
                                    <label for="" className="form-label">
                                        Specialist Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cust__form"
                                        {...formik.getFieldProps("name")}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className='form_errors'>{formik.errors.name}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-2">
                                    <label for="" className="form-label">
                                        Icon
                                    </label>
                                    <input
                                        type="file"
                                        //name='icon'
                                        id="upload-icon"
                                        className="form-control cust__form"
                                        onChange={(e) => onSelectImageHandler(e.target.files)}
                                        accept="image/jpeg,jpg,png"
                                        {...formik.getFieldProps("icon")}
                                    />
                                    {formik.touched.icon && formik.errors.icon && (
                                        <div className='form_errors'>{formik.errors.icon}</div>
                                    )}
                                    {/* <input type="hidden" name="file" value={icon} /> */}
                                </div>
                            </div>

                            {!loading ?
                                <div className="col-md-12 d-flex justify-content-between mt-3">
                                    <button type='submit' className="btn save-btn">Add</button>
                                    <button className="btn cancel-btn" onClick={goBack}>Back</button>
                                </div>
                                :
                                <LoadingDots />
                            }
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
};

export default Create;
