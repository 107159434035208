import Modal from "react-modal";
import CloseIcon from "../../dashboard/sidenavigation/icons/close";
import { formatDate } from "../FormatDate";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "100%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 900,
  },
};

// display stars for rating
function displayStarRating(ratingValue) {
  const maxRating = 5;
  const fullStar = "★";
  const emptyStar = "☆";

  const fullStars = Math.round(ratingValue);
  const emptyStars = maxRating - fullStars;

  const stars = fullStar.repeat(fullStars) + emptyStar.repeat(emptyStars);

  return `${stars}`;
}

const DoctorReviewAndRatingModel = ({ review, modalIsOpen, closeModal }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Doctor Details"
    >
      <div>
        <div className="row border-bottom">
          <div className="col-10">
            <h6>Review And Rating</h6>
          </div>
          <div className="col-2 d-flex justify-end">
            <button onClick={() => closeModal()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-4">Patient Name </div>
          <div className="col-8 text-secondary">
            {review?.patient?.firstName} {review?.patient?.lastName}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-4">Overall Rating </div>
          <div className="col-8">
            {displayStarRating(review?.avgRating)}{" "}
            {+review?.avgRating.toFixed(2)}
          </div>
        </div>
        {review?.ratings
          ? review.ratings.map((element) => (
              <div className="row mt-1">
                <div className="col-4">{element.ratingType.name} </div>
                <div className="col-8">
                  {displayStarRating(element.rating)}{" "}
                  {+element.rating.toFixed(2)}
                </div>
              </div>
            ))
          : false}
        <div className="row mt-1">
          <div className="col-4">Review </div>
          <div
            className="col-8 text-secondary"
            style={{ overflowWrap: "break-word" }}
          >
            {review?.review}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-4">Appointment Date & Time </div>
          <div className="col-8 text-secondary">
            {formatDate(review?.appointment?.date)}{" "}
            {review?.appointment?.startTime}-{review?.appointment?.endTime}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DoctorReviewAndRatingModel;
