import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../useAuth";
import DefaultAvatar from "./DefaultAvatar";
import Button from "./Button";
import AvatarEditor from "react-avatar-editor";
import { ZoomInIcon, ZoomOutIcon } from "@heroicons/react/solid";
import Slider from "rc-slider";
import SmallLoadingSpinner from "./SmallLoadingSpinner";
import classnames from "classnames";
import ChangePasswordForm from "./ChangePasswordForm";

import {
  serviceGetClinicDetails,
  serviceUpdateClinicProfile,
  servicePostFileUpload,
  updateClinicProfileImage,
} from "../services/clinic/clinicApiService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import FileDropzone from "./FileDropzone";
import { FormProvider, useForm } from "react-hook-form";
import LoadingDots from "./LoadingDots";

const GeneralAccountClinicSettings = () => {
  // const { register: registerName, handleSubmit: handleNameSubmit, formState: { errors: nameErrors } } = useForm();
  // const { register: registerContact, handleSubmit: handleContactSubmit, formState: { errors: contactErrors } } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clinicProfile, setClinicProfile] = useState({});
  const [avatarImage, setAvatarImage] = useState();
  const [avatarImageScale, setAvatarImageScale] = useState(1.2);

  const [isEditable, setIsEditable] = useState(false);

  const avatarImageInput = useRef(null);
  const avatarImageEditor = useRef(null);
  const [error, setError] = useState(false);
  const { user, userData } = useAuth();
  const [clinicFile, setClinicFile] = useState({});
  const [isReload, setIsReload] = useState(false);

  const methods = useForm({ mode: "onBlur" });

  const validationSchema = Yup.object({
    clinicName: Yup.string().required("Clinic name is required"),
    addressLine1: Yup.string().required("AddressLine1 is required"),
    landmark: Yup.string().required("landMark is required"),
    city: Yup.string().required("city is required"),
    state: Yup.string().required("state is required"),
    country: Yup.string().required("country is required"),
    pincode: Yup.number().required("pincode is required"),
    // openAt: Yup.string().required("Open at is required"),
    // closeAt: Yup.string().required("close at is required"),
  });

  const formik = useFormik({
    initialValues: {
      clinicName: "",
      addressLine1: "",
      landmark: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      openAt: "",
      closeAt: "",
      about: "",
      documents: [],
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      webHandleSubmit(values);
    },
  });

  useEffect(() => {
    fetchData();
  }, [isReload]);

  const fetchData = async () => {
    try {
      const response = await serviceGetClinicDetails(user.uid);
      setLoading(true);
      setIsReload(false);
      if (response.status == 0) {
        setLoading(false);
        toast.error(response.message);
      }
      if (response.status == 1) {
        setClinicProfile(response.data);
        const clinicData = response.data;
        if (clinicData.user.documents.length > 0) {
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
        const newValues = {
          clinicName: clinicData?.name,
          addressLine1: clinicData?.addressLine1,
          landmark: clinicData?.landmark,
          city: clinicData?.city,
          state: clinicData?.state,
          country: clinicData?.country,
          pincode: clinicData?.pincode,
          openAt: clinicData?.openAt,
          closeAt: clinicData?.closeAt,
          about: clinicData?.about,
        };
        formik.setValues(newValues);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  function onScaleChange(value) {
    setAvatarImageScale(value);
  }

  async function uploadAvatarImage() {
    setSubmitting(true);
    let avatarImageUrl = "";
    if (avatarImageEditor.current) {
      // const avatarDataUrl = avatarImageEditor.current
      //   .getImageScaledToCanvas()
      //   .toDataURL();
      //upload file
      let fileData = {
        file: avatarImage,
        destination: "/avatar",
      };
      avatarImageUrl = await servicePostFileUpload(fileData);

      let data = {
        avatarImageUrl: avatarImageUrl?.data?.filePath,
      };
      const registerData = await updateClinicProfileImage(data);

      if (registerData.status == 0) {
        setSubmitting(false);
        toast.error(registerData.message);
      }
      if (registerData.status == 1) {
        setSubmitting(false);
        setAvatarImage(null);
        toast.success(registerData.message);
        window.location.reload();
      }
    } else {
      return null;
    }
  }

  function isEmptyObject(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = _values;
      let fileData = {};
      let documents = [];

      data.documents = [];
      if (!isEmptyObject(clinicFile)) {
        //upload file
        fileData = {
          file: clinicFile,
          destination: "/clinic",
        };

        const fileResponse = await servicePostFileUpload(fileData);

        if (fileResponse.status == 0) {
          return toast.error(fileResponse.message);
        } else {
          documents = [
            {
              name: fileResponse.data.filePath.substring(
                fileResponse.data.filePath.lastIndexOf("/") + 1
              ),
              documentUrl: fileResponse.data.filePath,
            },
          ];
          data.documents = documents;
        }
      } else {
        documents = [
          {
            name: clinicProfile?.user?.documents[0]?.["name"],
            documentUrl: clinicProfile?.user?.documents[0]?.["documentUrl"],
          },
        ];
        data.documents = documents;
      }
      const responseData = await serviceUpdateClinicProfile(data);

      if (responseData.status == 0) {
        toast.error(responseData.message);
      }
      if (responseData.status == 1) {
        toast.success(responseData.message);
        setIsReload(true);
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  const handleFileSelect = async (_selectedFile) => {
    try {
      if (_selectedFile) {
        if (_selectedFile.size <= 5 * 1024 * 1024) {
          setClinicFile(_selectedFile);
        } else {
          setClinicFile(null);
          return toast.error("File size exceeds 5 MB");
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  function handleEditClick(e) {
    e.preventDefault();
    setIsEditable(true);
  }
  return (
    <>
      {!loading ? (
        <div>
          {/* <SectionTitle>Account Settings</SectionTitle> */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="profile__image__box">
                  {!avatarImage ? (
                    userData?.avatarImageUrl ? (
                      <img
                        src={userData?.avatarImageUrl}
                        className="margin-auto rounded-full col-span-3"
                        alt="Avatar"
                        style={{
                          height: "10vh",
                          width: "10vh",
                          maxWidth: "auto",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <DefaultAvatar className="m-auto profile__img__setting" />
                    )
                  ) : (
                    <>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <AvatarEditor
                          ref={avatarImageEditor}
                          className="justify-self-center w-full"
                          color={[0, 0, 0, 0.6]} // RGBA
                          scale={avatarImageScale}
                          rotate={0}
                          image={avatarImage}
                        />
                        <div className="flex items-center mt-2 w-[250px]">
                          <ZoomOutIcon className="h-5 w-5 text-gray-600 " />
                          <Slider
                            className="mx-3"
                            min={1}
                            max={2}
                            step={0.01}
                            defaultValue={1.2}
                            onChange={onScaleChange}
                          />
                          <ZoomInIcon className="h-5 w-5 text-gray-600" />
                        </div>
                      </div>
                    </>
                  )}
                  {avatarImage ? (
                    <>
                      <div className="row">
                        <div className="col-6 text-right">
                          <Button
                            onClick={() => setAvatarImage(null)}
                            layout="neutral"
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="col-6 text-left">
                          <Button
                            onClick={uploadAvatarImage}
                            layout="submit"
                            disabled={submitting}
                          >
                            {submitting && (
                              <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                            )}
                            Save
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        className="btn__profile__change"
                        onClick={() => avatarImageInput.current.click()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        ref={avatarImageInput}
                        onChange={(e) => setAvatarImage(e.target.files[0])}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="gap-3 setting__form">
            <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
              <form
                onSubmit={formik.handleSubmit}
                action="#"
                method="POST"
                className="col-span-2"
              >
                <div className="form__box__container">
                  <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">Clinic Name</label>
                      <input
                        type="text"
                        name="clinicName"
                        id="clinicName"
                        placeholder="Clinic Name"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.clinicName &&
                            formik.errors.clinicName,
                        })}
                        {...formik.getFieldProps("clinicName")}
                      />
                      {formik.touched.clinicName &&
                        formik.errors.clinicName && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.clinicName}
                          </p>
                        )}
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">Address</label>
                      <textarea
                        name="addressLine1"
                        id="addressLine1"
                        placeholder="Address Line 1"
                        className="form-control cust__form"
                        {...formik.getFieldProps("addressLine1")}
                      ></textarea>
                      {/* <input
                        type="text"
                        name="addressLine1"
                        id="addressLine1"
                        placeholder="Address Line 1"
                        className="form-control cust__form"
                        {...formik.getFieldProps("addressLine1")}
                      /> */}
                      {formik.touched.addressLine1 &&
                        formik.errors.addressLine1 && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.addressLine1}
                          </p>
                        )}
                    </div>

                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        className={classnames("form-control cust__form")}
                        disabled={true}
                        defaultValue={userData?.email}
                      />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">Mobile</label>
                      <input
                        type="text"
                        name="contactNumber"
                        placeholder="Mobile"
                        className={classnames("form-control cust__form")}
                        disabled={true}
                        defaultValue={userData?.contactNumber}
                      />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">LandMark</label>
                      <input
                        type="text"
                        name="landmark"
                        id="landmark"
                        placeholder="landmark"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.landmark && formik.errors.landmark,
                        })}
                        {...formik.getFieldProps("landmark")}
                      />
                      {formik.touched.landmark && formik.errors.landmark && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.landmark}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">City</label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.city && formik.errors.city,
                        })}
                        {...formik.getFieldProps("city")}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.city}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">State</label>
                      <input
                        type="text"
                        name="state"
                        id="state"
                        placeholder="State"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.state && formik.errors.state,
                        })}
                        {...formik.getFieldProps("state")}
                      />
                      {formik.touched.state && formik.errors.state && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.state}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">Country</label>
                      <input
                        type="text"
                        name="country"
                        id="country"
                        placeholder="Country"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.country && formik.errors.country,
                        })}
                        {...formik.getFieldProps("country")}
                      />
                      {formik.touched.country && formik.errors.country && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.country}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">Pin Code</label>
                      <input
                        type="text"
                        name="pincode"
                        id="pincode"
                        placeholder="Pincode"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.pincode && formik.errors.pincode,
                        })}
                        {...formik.getFieldProps("pincode")}
                      />
                      {formik.touched.pincode && formik.errors.pincode && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.pincode}
                        </p>
                      )}
                    </div>
                    {/* <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">Open At</label>
                      <input
                        type="time"
                        name="openAt"
                        id="openAt"
                        placeholder="Open At"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.openAt && formik.errors.openAt,
                        })}
                        {...formik.getFieldProps("openAt")}
                      />
                      {formik.touched.openAt && formik.errors.openAt && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.openAt}
                        </p>
                      )}
                    </div> */}

                    {/* <div className="col-span-2 sm:col-span-1">
                      <label className="form-label">Close At</label>
                      <input
                        type="time"
                        name="closeAt"
                        id="closeAt"
                        placeholder="Close At"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.closeAt && formik.errors.closeAt,
                        })}
                        {...formik.getFieldProps("closeAt")}
                      />
                      {formik.touched.closeAt && formik.errors.closeAt && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.closeAt}
                        </p>
                      )}
                    </div> */}

                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">About</label>
                      <textarea
                        type="text"
                        name="about"
                        id="about"
                        placeholder="About"
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.about && formik.errors.about,
                        })}
                        {...formik.getFieldProps("about")}
                      />
                      {formik.touched.about && formik.errors.about && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.about}
                        </p>
                      )}
                    </div>
                    <div className="col-span-12 sm:col-span-2">
                      <label className="form-label">Clinic Document</label>
                      <div className="form__box__container">
                        {!isEditable ? (
                          <div>
                            <a
                              target="_blank"
                              href={
                                clinicProfile?.user?.documents[0]?.[
                                  "documentUrl"
                                ]
                              }
                            >
                              {clinicProfile?.user?.documents[0]["name"]}{" "}
                            </a>
                            {"  "}&nbsp;&nbsp;
                            <a href="#" onClick={handleEditClick}>
                              Edit Document
                            </a>
                          </div>
                        ) : (
                          <div className="row justify-content-center mb-3">
                            <div className="col-md-8">
                              <input
                                type="hidden"
                                className="form-control"
                                name="files"
                              ></input>
                              {formik.touched.files && formik.errors.files && (
                                <div className="form_errors">
                                  {formik.errors.files}
                                </div>
                              )}
                              <FormProvider {...methods}>
                                <FileDropzone
                                  accept=".pdf, image/jpeg, image/png, image/jpg"
                                  name="idImage"
                                  handleFileSelect={handleFileSelect}
                                />
                                <div className="form_errors">
                                  Note: Please upload image or PDF files only,
                                  and the file size should be below 5 MB{" "}
                                </div>
                              </FormProvider>
                            </div>
                            <div></div>
                          </div>
                        )}
                        {/* <button
                      className="btn btn_view"
                      onClick={() => handleEditClick()}
                    >
                      Edit
                    </button> */}
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <Button type="submit" disabled={loading}>
                        {loading && (
                          <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                        )}
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="py-3 border-b-2 border-gray-400 items-center">
              <div class="upcoming__head">
                <h2>Change password</h2>
              </div>
              <div className="form__box__container">
                <ChangePasswordForm />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default GeneralAccountClinicSettings;
